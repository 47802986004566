"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['data', 'show'],
  data: function data() {
    return {
      currentPage4: 2,
      currentPageRecord: 1,
      limitRecord: 1,
      totalRecord: 1
    };
  },
  created: function created() {},
  methods: {
    handleSizeRecordChange: function handleSizeRecordChange() {},
    handleCurrentRecordChange: function handleCurrentRecordChange() {},
    // 关闭弹窗
    closeDialog: function closeDialog() {
      this.$emit('update:show', false);
    },
    handleSizeChange: function handleSizeChange() {},
    handleCurrentChange: function handleCurrentChange() {}
  }
};