var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    _vm._l(_vm.goodsSkuList, function (val, i) {
      return _c("div", { key: i, staticClass: "content-box" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "margin-right": "10px",
            },
          },
          [
            _c(
              "el-checkbox",
              {
                staticStyle: { "font-size": "18px", "font-weight": "700" },
                model: {
                  value: val.checked,
                  callback: function ($$v) {
                    _vm.$set(val, "checked", $$v)
                  },
                  expression: "val.checked",
                },
              },
              [_vm._v(_vm._s(i + 1))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "tab-box" },
              _vm._l(val.radioOptions, function (ra) {
                return _c(
                  "el-radio",
                  {
                    attrs: { label: ra.label, border: "" },
                    on: { input: _vm.radioInput },
                    model: {
                      value: val.tabRadio,
                      callback: function ($$v) {
                        _vm.$set(val, "tabRadio", $$v)
                      },
                      expression: "val.tabRadio",
                    },
                  },
                  [_vm._v("\n          " + _vm._s(ra.titl) + "\n        ")]
                )
              }),
              1
            ),
            _vm._v(" "),
            val.tabRadio == 1
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "goods-title-box" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("商品标题："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: {
                          size: "mini",
                          maxlength: "60",
                          "show-word-limit": "",
                        },
                        model: {
                          value: val.goodsName,
                          callback: function ($$v) {
                            _vm.$set(val, "goodsName", $$v)
                          },
                          expression: "val.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "goods-pix-box" }, [
                    _c(
                      "div",
                      { staticClass: "title-column" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("商品主图："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "del-btn",
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSplit(i)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "img-cloumn" },
                      [
                        _c("imgHandleCom", {
                          ref: "imgHandleCom",
                          refInFor: true,
                          attrs: { skulist: val.carouselGalleryList },
                        }),
                        _vm._v(" "),
                        _vm._m(0, true),
                      ],
                      1
                    ),
                  ]),
                ])
              : _c("skuBox", {
                  ref: "skuBoxRef",
                  refInFor: true,
                  staticStyle: { "margin-top": "20px" },
                  attrs: { specList: val.specList, specSkuList: val.skuList },
                }),
            _vm._v(" "),
            val.tabRadio == 1
              ? _c(
                  "div",
                  { staticClass: "status-box" },
                  [
                    _c(
                      "el-steps",
                      {
                        staticStyle: { width: "280px" },
                        attrs: { active: val.stepActive, "align-center": "" },
                      },
                      [
                        _c("el-step", { attrs: { title: "等待裂变" } }),
                        _vm._v(" "),
                        _c("el-step", { attrs: { title: "裂变中" } }),
                        _vm._v(" "),
                        !val.stepActiveStatus
                          ? _c("el-step", {
                              attrs: { title: "裂变失败", status: "error" },
                            })
                          : _c("el-step", { attrs: { title: "裂变成功" } }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-box" }, [
      _c("p", [_vm._v("支持长按图片拖拽交换位置")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "商品主图最多设置5张(格式要求：png、jpg、jpeg格式，图片尺寸至少600×600比例1:1，大小不超过2M）"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }