var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sku-content" },
    [
      _vm._l(_vm.specList, function (spec, index) {
        return _c("div", { key: index, staticClass: "sku-box-item" }, [
          _c("div", { staticClass: "option-title-box" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "sku" }, [_vm._v("(主)  规格")]),
              _vm._v(" \n        "),
              _c("span", [_vm._v(_vm._s(Object.keys(spec)[0]))]),
              _vm._v("  \n        "),
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.getListLength(spec)) + "个"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "options-box" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.AIChangeSkuAll(Object.keys(spec)[0], index)
                      },
                    },
                  },
                  [_vm._v(" AI一键修改SKU文字")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-refresh" },
                    on: { click: _vm.skuReStore },
                  },
                  [_vm._v("一键还原")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sku-options-box" },
            _vm._l(spec[Object.keys(spec)[0]], function (item, i) {
              return _c(
                "div",
                { key: i, staticClass: "options-item-box" },
                [
                  _c("el-input", {
                    staticStyle: { width: "130px" },
                    attrs: { size: "mini" },
                    on: {
                      change: function ($event) {
                        return _vm.inputHandle($event, i)
                      },
                    },
                    model: {
                      value: spec[Object.keys(spec)[0]][i],
                      callback: function ($$v) {
                        _vm.$set(spec[Object.keys(spec)[0]], i, $$v)
                      },
                      expression: "spec[Object.keys(spec)[0]][i]",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { size: "mini", icon: "el-icon-refresh" },
                    on: {
                      click: function ($event) {
                        _vm.skuSingleRestore(Object.keys(spec)[0], index, i)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.AIChangeSku(Object.keys(spec)[0], index, i)
                        },
                      },
                    },
                    [_vm._v("AI")]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-top-box" },
        [
          _c("strong", [_vm._v("SKU数据")]),
          _vm._v("  \n    "),
          _c("span", { staticClass: "number" }, [_vm._v("5个")]),
          _vm._v(" "),
          _c("strong", [_vm._v("    商品参考价：")]),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "250px" },
            attrs: { size: "mini" },
            model: {
              value: _vm.val,
              callback: function ($$v) {
                _vm.val = $$v
              },
              expression: "val",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c(
                "el-table",
                {
                  ref: "myTable",
                  staticStyle: { width: "90%" },
                  attrs: { data: _vm.specSkuList },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "specSku", label: "SKU规格", width: "180" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "来原价格", width: "180" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "skuId", label: "商品编码", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.skuId,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "skuId", $$v)
                                },
                                expression: "scope.row.skuId",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "multiPrice",
                      label: "拼单价",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("拼单价")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSome("multiPrice", 1)
                                  },
                                },
                              },
                              [_vm._v("批量改")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.multiPrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "multiPrice", $$v)
                                },
                                expression: "scope.row.multiPrice",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "quantity", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("库存")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSome("quantity", 2)
                                  },
                                },
                              },
                              [_vm._v("批量改")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "130px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.quantity,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "quantity", $$v)
                                },
                                expression: "scope.row.quantity",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "thumbUrl", label: "预览图", width: "240" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("预览图")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSome("thumbUrl", 3)
                                  },
                                },
                              },
                              [_vm._v("批量改")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  "open-delay": 300,
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    slot: "content",
                                    src: scope.row.thumbUrl,
                                  },
                                  slot: "content",
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: {
                                    width: "70px",
                                    height: "70px",
                                  },
                                  attrs: { src: scope.row.thumbUrl },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  width: "70px",
                                  "text-align": "center",
                                  "margin-top": "-7px",
                                  color: "#2978FF",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("设置")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.editModalVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editModalVisible = $event
            },
          },
        },
        [
          _vm._v("\n    请输入批量修改的内容\n    "),
          _c("el-input", {
            model: {
              value: _vm.editVal,
              callback: function ($$v) {
                _vm.editVal = $$v
              },
              expression: "editVal",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editModalVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editALlSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }