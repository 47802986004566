"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemSetting = require("@/api/systemSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['show'],
  data: function data() {
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      upImg: '',
      //上传的base64图片
      uploadImgs: [],
      //上传的图片列表
      activeName: '1',
      linkVal: null,
      linkImgList: [] //链接生成的图片
    };
  },
  methods: {
    // 生成图片操作
    generateImg: function generateImg() {
      this.linkImgList = this.linkVal.split(/[\r\n]+/).filter(function (line) {
        return line.length > 0;
      });
    },
    // 上传图片的逻辑
    handleUploadForm: function handleUploadForm() {
      var _this = this;
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(this.$parent, 'this.$parent==uploadImg');
      var param = {
        mallId: this.$parent.mallId,
        image: this.upImg
      };
      (0, _systemSetting.uploadImage)(param).then(function (res) {
        loading.close();
        _this.$message.success('上传成功');
        console.log(res, 'uploadImage', _this.$parent);
        _this.uploadImgs.push(res.goodsImageUploadResponse.imageUrl);
        _this.$parent.uploadNum = _this.uploadImgs.length;
        _this.$parent.myfirstPic = _this.uploadImgs;
        _this.fileList.push({
          url: res.goodsImageUploadResponse.imageUrl
        });
        _this.$forceUpdate();
      }).catch(function (res) {
        loading.close();
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      console.log(file, 44441);
      var reader = new FileReader();
      reader.onload = function (e) {
        _this2.upImg = e.target.result;
        _this2.handleUploadForm();
        console.log(e.target.result, 'gh');
      };
      reader.onerror = function (error) {
        console.error('Error reading file:', error);
      };
      reader.readAsDataURL(file);
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      console.log(file, 'handlePictureCardPreview');
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    closeDialog: function closeDialog() {
      this.$emit('update:show', false);
    }
  }
};