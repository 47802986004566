var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "_container" },
      [
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c("span", { staticClass: "title" }, [_vm._v("店铺")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "custom-select",
                attrs: {
                  "popper-append-to-body": false,
                  placeholder: "请选择",
                  size: "mini",
                  clearable: "",
                },
                on: { change: _vm.storeChange },
                model: {
                  value: _vm.valueSelect,
                  callback: function ($$v) {
                    _vm.valueSelect = $$v
                  },
                  expression: "valueSelect",
                },
              },
              _vm._l(_vm.optionsSelect, function (item) {
                return _c(
                  "el-option",
                  {
                    key: item.id,
                    attrs: {
                      disabled: item.onlineStatus == 0,
                      label: item.mallName,
                      value: item.id,
                    },
                  },
                  [
                    _c("span", { staticStyle: { float: "left" } }, [
                      _vm._v(_vm._s(item.mallName)),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleTabsClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "导出SKU", name: "first" } }),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "导出SPU", name: "second" } }),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "导出记录", name: "third" } }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.activeName !== "third"
          ? _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.form, "label-width": "100px" },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品分类" } },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "98%" },
                              attrs: {
                                options: _vm.options,
                                props: {
                                  multiple: true,
                                  value: "catId",
                                  label: "catName",
                                  lazy: true,
                                  lazyLoad: function lazyLoad(node, resolve) {
                                    _vm.loadNodes(node, resolve)
                                  },
                                },
                                "collapse-tags": "",
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.goodsClassifyArray,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "goodsClassifyArray", $$v)
                                },
                                expression: "form.goodsClassifyArray",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: {
                                  placeholder: "请选择商品状态",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "销售中商品", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "未上架商品", value: "2" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "已售罄商品", value: "3" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "已删除", value: "4" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "运费模版" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: {
                                  placeholder: "请选择运费模版",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.costTemplateId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "costTemplateId", $$v)
                                  },
                                  expression: "form.costTemplateId",
                                },
                              },
                              _vm._l(
                                _vm.templatePostList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: item.templateId,
                                    attrs: {
                                      label: item.templateName,
                                      value: item.templateId,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品ID" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "多个用','或空格分隔'",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.goodsIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "goodsIds", $$v)
                                },
                                expression: "form.goodsIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "标题" } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.goodsNames,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "goodsNames", $$v)
                                  },
                                  expression: "form.goodsNames",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      slot: "prepend",
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    slot: "prepend",
                                    model: {
                                      value: _vm.form.isContains,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isContains", $$v)
                                      },
                                      expression: "form.isContains",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "包含关键词",
                                        value: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "不包含关键词",
                                        value: "2",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "拼单价" } }, [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticStyle: { width: "47%" },
                                attrs: {
                                  placeholder: "最小价格",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.minMultiPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "minMultiPrice", $$v)
                                  },
                                  expression: "form.minMultiPrice",
                                },
                              }),
                              _vm._v(" -\n              "),
                              _c("el-input", {
                                staticStyle: { width: "47%" },
                                attrs: {
                                  placeholder: "最大价格",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.maxMultiPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "maxMultiPrice", $$v)
                                  },
                                  expression: "form.maxMultiPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "库存范围" } }, [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticStyle: { width: "47%" },
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.minQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "minQuantity", $$v)
                                  },
                                  expression: "form.minQuantity",
                                },
                              }),
                              _vm._v(" -\n              "),
                              _c("el-input", {
                                staticStyle: { width: "47%" },
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.maxQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "maxQuantity", $$v)
                                  },
                                  expression: "form.maxQuantity",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否预售" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.isPreSale,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isPreSale", $$v)
                                  },
                                  expression: "form.isPreSale",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "全部商品", value: "" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "是", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "否", value: "0" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发货时间承诺" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.shipmentLimitSecond,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "shipmentLimitSecond",
                                      $$v
                                    )
                                  },
                                  expression: "form.shipmentLimitSecond",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "全部", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "24小时", value: "2" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "48小时", value: "3" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "3天", value: "4" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "5天", value: "5" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "7天", value: "6" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "8天", value: "7" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "10天", value: "8" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否二手" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.secondHand,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "secondHand", $$v)
                                  },
                                  expression: "form.secondHand",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "全部", value: "" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "是", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "否", value: "0" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "七天退换货" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.isRefundable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isRefundable", $$v)
                                  },
                                  expression: "form.isRefundable",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "全部", value: "" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "是", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "否", value: "0" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "假一赔十" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.isFolt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isFolt", $$v)
                                  },
                                  expression: "form.isFolt",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "全部", value: "" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "是", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "否", value: "0" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "98%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.goodsType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "goodsType", $$v)
                                  },
                                  expression: "form.goodsType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "全部", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "普通商品", value: "2" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "海外进口", value: "3" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "海外BC直邮", value: "4" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "CC行邮", value: "5" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "平台卡券", value: "6" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品编码" } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "多个用','或空格分隔",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.goodsCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "goodsCode", $$v)
                                  },
                                  expression: "form.goodsCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      slot: "prepend",
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    slot: "prepend",
                                    model: {
                                      value: _vm.form.goodsCodeType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "goodsCodeType", $$v)
                                      },
                                      expression: "form.goodsCodeType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "精确匹配", value: "1" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "包含任一关键词",
                                        value: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "包含全部关键词",
                                        value: "3",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "不含任一关键词",
                                        value: "4",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "不含全部关键词",
                                        value: "5",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "前缀搜索", value: "6" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "后缀搜索", value: "7" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "创建时间" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "98%" },
                              attrs: {
                                type: "datetimerange",
                                clearable: "",
                                "picker-options": _vm.pickerOptions,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                align: "right",
                              },
                              model: {
                                value: _vm.form.createTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "createTime", $$v)
                                },
                                expression: "form.createTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("el-divider"),
        _vm._v(" "),
        _vm.activeName == "first"
          ? _c("div", { staticClass: "exportBox" }, [
              _c(
                "div",
                { staticClass: "boxTop" },
                [
                  _c("div", { staticClass: "boxTitle" }, [
                    _vm._v("导出SKU属性选择"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { "margin-left": "12px" },
                      attrs: { indeterminate: _vm.isIndeterminateSKU },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAllSKU,
                        callback: function ($$v) {
                          _vm.checkAllSKU = $$v
                        },
                        expression: "checkAllSKU",
                      },
                    },
                    [_vm._v("全选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.handleCheckReverseChange },
                      model: {
                        value: _vm.reverseSKU,
                        callback: function ($$v) {
                          _vm.reverseSKU = $$v
                        },
                        expression: "reverseSKU",
                      },
                    },
                    [_vm._v("反选")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ridioInfo" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckedSKUChange },
                      model: {
                        value: _vm.checkedSKU,
                        callback: function ($$v) {
                          _vm.checkedSKU = $$v
                        },
                        expression: "checkedSKU",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          on: {
                            start: function ($event) {
                              _vm.drag = true
                            },
                            end: _vm.onDragEnd,
                          },
                          model: {
                            value: _vm.skus,
                            callback: function ($$v) {
                              _vm.skus = $$v
                            },
                            expression: "skus",
                          },
                        },
                        _vm._l(_vm.skus, function (item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item,
                              staticStyle: { "margin-bottom": "15px" },
                              attrs: {
                                label: item,
                                disabled: item == "SKUID" ? true : false,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-upload2" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeName == "second"
          ? _c("div", { staticClass: "exportBox" }, [
              _c(
                "div",
                { staticClass: "boxTop" },
                [
                  _c("div", { staticClass: "boxTitle" }, [
                    _vm._v("导出SKU属性选择"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "remark" }, [
                    _vm._v("(支持长按拖动属性进行调整排序)"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminateSPU },
                      on: { change: _vm.handleCheckSPUAllChange },
                      model: {
                        value: _vm.checkAllSPU,
                        callback: function ($$v) {
                          _vm.checkAllSPU = $$v
                        },
                        expression: "checkAllSPU",
                      },
                    },
                    [_vm._v("全选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.handleCheckSPUReverseChange },
                      model: {
                        value: _vm.reverseSPU,
                        callback: function ($$v) {
                          _vm.reverseSPU = $$v
                        },
                        expression: "reverseSPU",
                      },
                    },
                    [_vm._v("反选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "selectBox" },
                    [
                      _c("div", { staticClass: "selectTitle" }, [
                        _vm._v("勾选记忆"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { margin: "0 10px" },
                          attrs: { placeholder: "请选择", size: "small" },
                          model: {
                            value: _vm.memory,
                            callback: function ($$v) {
                              _vm.memory = $$v
                            },
                            expression: "memory",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "默认", value: "1" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ridioInfo" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckedSPUChange },
                      model: {
                        value: _vm.checkedSPU,
                        callback: function ($$v) {
                          _vm.checkedSPU = $$v
                        },
                        expression: "checkedSPU",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          on: {
                            start: function ($event) {
                              _vm.drag = true
                            },
                            end: _vm.onDragSPUEnd,
                          },
                          model: {
                            value: _vm.spus,
                            callback: function ($$v) {
                              _vm.spus = $$v
                            },
                            expression: "spus",
                          },
                        },
                        _vm._l(_vm.spus, function (item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item,
                              staticStyle: { "margin-bottom": "15px" },
                              attrs: {
                                label: item,
                                disabled: item == "SKUID" ? true : false,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-upload2" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeName == "third"
          ? _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData.data,
                      "header-cell-style": { background: "#F5F6FA" },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "fileName", label: "文件名称" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "type", label: "导出类型" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "goodsNum", label: "导出商品数量" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "status", label: "导出状态" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 1
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "success",
                                          size: "mini",
                                        },
                                      },
                                      [_vm._v("已完成")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "info", size: "mini" } },
                                      [_vm._v("导出中")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3738758137
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "createTime", label: "导出时间" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      href: scope.row.url,
                                    },
                                  },
                                  [_vm._v("下载文件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除记录")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4144779659
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [10, 20, 40, 60, 80],
                        "page-size": _vm.tableFrom.limit,
                        "current-page": _vm.tableFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.tableData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "导出任务信息",
              visible: _vm.exportOpen,
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.exportOpen = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "formExport",
                attrs: { model: _vm.formExport, "label-width": "100px" },
              },
              [
                _c("el-form-item", { attrs: { label: "导出商品数" } }, [
                  _c("div", [
                    _c("span", { staticStyle: { color: "#F56C6C" } }, [
                      _vm._v(_vm._s(_vm.formExport.goodsNum)),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "文件名称" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formExport.fileName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formExport, "fileName", $$v)
                        },
                        expression: "formExport.fileName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.exportOpen = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleExportChange },
                  },
                  [_vm._v("开始导出")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }