"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  created: function created() {
    var _this = this;
    window.chrome.webview.hostObjects.Main.addEventListener('NotificationNewUpdateVersion', function () {
      _this.$notify({
        title: '警告',
        dangerouslyUseHTMLString: true,
        message: '发现新版本!<strong @click="restart">立即重启</strong>',
        type: 'warning',
        duration: 10000,
        onClick: function onClick() {
          window.chrome.webview.hostObjects.Main.Restart();
        }
      });
    });
  }
};