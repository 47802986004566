import request from '@/utils/request';

/**
 * 申诉请求接口
 */
export function applyRequest(data) {
  return request({
    url: '/admin/pdd/admin/ASS',
    method: 'POST',
    data: data
  });
}

/**
 * 申诉与请求接口
 */
export function pretectapplyRequest(data) {
  return request({
    url: '/admin/pdd/common/proHttpSS',
    method: 'POST',
    data: data
  });
}

/**
 *
 */
export function getCreateUrlApi() {
  return request({
    url: '/admin/pdd/store/createUrl',
    method: 'GET'
  });
}

/**
 *
 */
export function getPddCallbackApi(params) {
  return request({
    url: '/admin/pdd/store/pddCallback',
    method: 'GET',
    params: params
  });
}

/**
 * 获取爆款分类
 */
export function hotCategoryApi(params) {
  return request({
    url: '/admin/pdd/topSellingList/getFirstCate',
    method: 'GET',
    params: params
  });
}

/**
 * 刪除店鋪
 */
export function delStoreApi(params) {
  return request({
    url: '/v3/admin/pdd/userMall/deleteUserMall',
    method: 'GET',
    params: params
  });
}

/**
 * 获取爆款列表
 */
export function hotCategoryListApi(params) {
  return request({
    url: '/admin/pdd/topSellingList/getPage',
    method: 'GET',
    params: params
  });
}

/**
 * 检测视频状态
 */
export function videoUplodInfoApi(data) {
  return request({
    url: '/admin/pdd/goods/fileInfo',
    method: 'POST',
    data: data
  });
}

/**
 * 上传视频到视频空间
 */
export function videoUplodApi(data, id) {
  return request({
    url: '/admin/pdd/goods/videoUpload?mallId=' + id,
    method: 'POST',
    data: data
  });
}

/**
 * 预请求小二推荐类目
 */
export function pretectApiRecomendRequest(data) {
  return request({
    url: '/admin/pdd/common/proHttpTJLM',
    method: 'POST',
    data: data
  });
}

/**
 * 预请求小二推荐商品
 */
export function pretectApiRecomendGoodsRequest(data) {
  return request({
    url: '/admin/pdd/common/proHttpTJSP',
    method: 'POST',
    data: data
  });
}

/**
 * 预请求接口
 */
export function pretectApiRequest(data) {
  return request({
    url: '/admin/pdd/common/proHttp',
    method: 'POST',
    data: data
  });
}

/**
 * 开通扣除积分
 * @param prams
 */
export function openDelInApi(data) {
  return request({
    url: 'admin/pdd/storeActivateFunction/activate',
    method: 'POST',
    data: data
  });
}

/**
 * 获取云端店铺列表
 * @param prams
 */
export function getUserStores(data) {
  return request({
    url: '/admin/pdd/admin/userStores',
    method: 'POST',
    data: data
  });
}

/**
 * 获取授权码
 * @param prams
 */
export function addAuthCodeApi(params) {
  return request({
    url: '/v3/admin/pdd/admin/bindAuthCode',
    method: 'GET',
    params: params
  });
}

/**
 * 获取授权码
 * @param prams
 */
export function getAuthCodeApi(params) {
  return request({
    url: '/v3/admin/pdd/admin/pddCallback',
    method: 'GET',
    params: params
  });
}

/**
 * 检测登录状态
 * @param prams
 */
export function checkLoginStatusApi(data) {
  return request({
    url: '/v3/admin/pdd/admin/checkStoreStatus',
    method: 'POST',
    data: data
  });
}

/**
 * 删除店铺
 */
export function deleteStroeApi(data) {
  return request({
    url: '/admin/pdd/admin/deleteStore',
    method: 'POST',
    data: data
  });
}

/**
 * 绑定店铺与请求
 */
export function bindStorePreApi(data) {
  return request({
    url: '/admin/pdd/common/proHttpStore',
    method: 'POST',
    data: data
  });
}

/**
 * 绑定店铺
 */
export function bindStoreApi(data) {
  return request({
    url: '/admin/pdd/admin/bindStore',
    method: 'POST',
    data: data
  });
}

/**
 * 工单列表统计
 * @param prams
 */
export function todoData(data) {
  return request({
    url: '/admin/pdd/admin/todoData',
    method: 'POST',
    data: data
  });
}

/**
 * 工单列表统计
 * @param prams
 */
export function todoList(data) {
  return request({
    url: '/admin/pdd/admin/todoList',
    method: 'POST',
    data: data
  });
}

/**
 * 店铺统计
 * @param prams
 */
export function storeStatistic(data) {
  return request({
    url: '/admin/pdd/admin/storeStatistic',
    method: 'POST',
    data: data
  });
}

/**
 * 店铺明细
 * @param prams
 */
export function storeDetail(data) {
  return request({
    url: '/admin/pdd/admin/storeDetail',
    method: 'POST',
    data: data
  });
}

/**
 * 推广统计
 * @param prams
 */
export function extensionReport(data) {
  return request({
    url: '/admin/pdd/admin/extensionReport',
    method: 'POST',
    data: data
  });
}

/**
 * 运营报表
 * @param prams
 */
export function storeReport(data) {
  return request({
    url: '/admin/pdd/admin/storeReport',
    method: 'POST',
    data: data
  });
}