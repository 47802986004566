var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "_container" },
      [
        !_vm.isEdit
          ? [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "商品批量修改", name: "tab1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "批量修改历史", name: "tab2" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeName == "tab1"
                ? _c("div", { staticClass: "edit-all-box" }, [
                    _c(
                      "div",
                      { staticClass: "edit-top-box" },
                      _vm._l(_vm.list1, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "edit-item",
                            on: {
                              click: function ($event) {
                                return _vm.handles(item.title)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.title) + "\n          ")]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "edit-top-box",
                        staticStyle: { "margin-top": "30px" },
                      },
                      _vm._l(_vm.list2, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "edit-item",
                            staticStyle: { background: "#DFE6FF" },
                            on: {
                              click: function ($event) {
                                return _vm.handles(item.title)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.title) + "\n          ")]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "edit-top-box",
                        staticStyle: { "margin-top": "30px" },
                      },
                      _vm._l(_vm.list3, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "edit-item",
                            on: {
                              click: function ($event) {
                                return _vm.handles(item.title)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.title) + "\n          ")]
                        )
                      }),
                      0
                    ),
                  ])
                : _c(
                    "div",
                    { staticClass: "edit-all-history" },
                    [
                      _c(
                        "div",
                        { staticClass: "top-options-box" },
                        _vm._l(20, function (item) {
                          return _c(
                            "div",
                            {
                              staticClass: "option-item",
                              on: {
                                click: function ($event) {
                                  return _vm.changeOption()
                                },
                              },
                            },
                            [_vm._v("\n            导入修改商品\n          ")]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", "margin-top": "20px" },
                          attrs: {
                            data: _vm.tableData,
                            "header-cell-style": {
                              background: "#eef1f6",
                              fontWeight: "700",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "修改内容",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n              34534\n            "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1584097605
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              align: "center",
                              width: "100",
                              label: "创建时间",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n              2021-10-11\n            "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1142553456
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "修改商品数",
                              width: "100",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n              3423\n            "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3399951766
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "状态", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("上架中")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              717538103
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsQuantity",
                              label: "成功数",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "presaleWay",
                              label: "失败数",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "presaleWay",
                              label: "无效商品数",
                              width: "200",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "presaleWay",
                              label: "跳过未更改数",
                              width: "200",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "presaleWay",
                              width: "200",
                              label: "最近恢复时间",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              align: "center",
                              label: "操作",
                              width: "200",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goodsSplit(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("裂变")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSplitDiolag(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("裂变记录\n                "),
                                          scope.row.fissionAddAmount > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      scope.row.fissionAddAmount
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              679691145
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          : [
              _c("div", { staticClass: "edit-box-content" }, [
                _c(
                  "div",
                  { staticClass: "top-box" },
                  [
                    _c(
                      "span",
                      { staticClass: "toptile" },
                      [
                        _vm._v(
                          "批量修改" + _vm._s(_vm.editTitle) + "\n            "
                        ),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "800",
                              trigger: "click",
                            },
                            model: {
                              value: _vm.pVisible,
                              callback: function ($$v) {
                                _vm.pVisible = $$v
                              },
                              expression: "pVisible",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "options-edit-box" },
                              _vm._l(_vm.editList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "options-edit-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editHandle(item.title)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.title) + "\n                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  type: "primary",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v("切换"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "title",
                            staticStyle: { "margin-left": "20px" },
                          },
                          [_vm._v("店铺")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "custom-select",
                            attrs: {
                              "popper-append-to-body": false,
                              placeholder: "请选择",
                              size: "mini",
                            },
                            on: { change: _vm.storeChange },
                            model: {
                              value: _vm.valueSelect,
                              callback: function ($$v) {
                                _vm.valueSelect = $$v
                              },
                              expression: "valueSelect",
                            },
                          },
                          _vm._l(_vm.optionsSelect, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  disabled: item.onlineStatus == 0,
                                  label: item.mallName,
                                  value: item.id,
                                },
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.mallName)),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "drap-btn",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.isEdit = false
                          },
                        },
                      },
                      [_vm._v("回退")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "edit-content-container" }, [
                  _c("span", { staticClass: "titl" }, [_vm._v("修改范围")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "edit-conten-area" },
                    [
                      _c(
                        "el-tabs",
                        { attrs: { type: "border-card" } },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "按范围修改" } },
                            [
                              _c("div", { staticClass: "top-input-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    商品分类\n                     \n                    "
                                    ),
                                    _c("el-cascader", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        options: _vm.options,
                                        clearable: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    商品状态\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    运费模板\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                     \n                     \n                    商品ID\n                     \n                    "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: { placeholder: "请输入内容" },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                     \n                     \n                     \n                     \n                    标题\n                     \n                    "
                                    ),
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "input-with-select",
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              slot: "prepend",
                                              placeholder: "请选择",
                                            },
                                            slot: "prepend",
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "餐厅名",
                                                value: "1",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "订单号",
                                                value: "2",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "用户电话",
                                                value: "3",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "input-item" }, [
                                  _vm._v(
                                    "\n                    拼单价格\n                     \n                    "
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "input-box-d" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "47%" },
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                      -\n                      "
                                      ),
                                      _c("el-input", {
                                        staticStyle: { width: "47%" },
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "input-item" }, [
                                  _vm._v(
                                    "\n                    库存范围\n                     \n                    "
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "input-box-d" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "47%" },
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                      -\n                      "
                                      ),
                                      _c("el-input", {
                                        staticStyle: { width: "47%" },
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    是否预售\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    发货时间承诺\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "72%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    是否二手\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    七天退换货\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "78%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    假一赔十\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    商品类型\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    商品编码\n                     \n                    "
                                    ),
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "input-with-select",
                                        staticStyle: { width: "80%" },
                                        attrs: {
                                          placeholder: "用逗号,或分号隔开",
                                        },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              slot: "prepend",
                                              placeholder: "",
                                            },
                                            slot: "prepend",
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "餐厅名",
                                                value: "1",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "订单号",
                                                value: "2",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "用户电话",
                                                value: "3",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    创建时间\n                     \n                    "
                                    ),
                                    _c("el-time-picker", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        "is-range": "",
                                        "range-separator": "-",
                                        "start-placeholder": "开始时间",
                                        "end-placeholder": "结束时间",
                                        placeholder: "选择时间范围",
                                      },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    标记美图\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "80%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    是否搬家商品\n                     \n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "72%" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            clearable: "",
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-item" },
                                  [
                                    _vm._v(
                                      "\n                    搬家时间\n                     \n                    "
                                    ),
                                    _c("el-time-picker", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        "is-range": "",
                                        "range-separator": "-",
                                        "start-placeholder": "开始时间",
                                        "end-placeholder": "结束时间",
                                        placeholder: "选择时间范围",
                                      },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "按商品修改" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0 auto",
                                    display: "block",
                                  },
                                  attrs: { type: "primary" },
                                },
                                [_vm._v("选择商品2个")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "按商品ID修改" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 15, maxRows: 15 },
                                  placeholder: "请输入内容",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "按导入内容修改" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    padding: "20px 0",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { type: "primary" } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-download",
                                      }),
                                      _vm._v("下载模板"),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    { attrs: { type: "primary" } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload",
                                      }),
                                      _vm._v("上传模板"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "titl" }, [_vm._v("修改内容")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "_wrapper" }, [
                    _vm.editTitle == "标题" || _vm.editTitle == "短标题"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c("div", { staticClass: "title-box" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "padding-top": "6px",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  },
                                  [_vm._v("方式1：")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._v(
                                  "\n                  增加前缀内容：\n                  "
                                ),
                                _c("el-input", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入前缀",
                                  },
                                }),
                                _vm._v(
                                  "\n                   \n                   \n                  增加后缀：\n                  "
                                ),
                                _c("el-input", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入后缀",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.checked,
                                          callback: function ($$v) {
                                            _vm.checked = $$v
                                          },
                                          expression: "checked",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "如果修改后的标题超过60个字，自动从"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "原标题",
                                            value: "1",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "新标题",
                                            value: "2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      "\n                     \n                    的\n                     \n\n                    "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "后面", value: "1" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "前面", value: "2" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      "\n                    删除相应的字数\n                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#f01",
                                          "margin-left": "10px",
                                        },
                                      },
                                      [_vm._v("(请慎重选择此选项)")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-box" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "padding-top": "6px",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  },
                                  [_vm._v("方式2：")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              _vm._l(_vm.titleArr, function (item, index) {
                                return _c(
                                  "p",
                                  {
                                    key: index,
                                    staticStyle: { "margin-bottom": "10px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    商品关键字：\n                    "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入查找内容",
                                      },
                                      model: {
                                        value: item.new,
                                        callback: function ($$v) {
                                          _vm.$set(item, "new", $$v)
                                        },
                                        expression: "item.new",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                     \n                     \n                    替换为:\n                    "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入查找内容",
                                      },
                                      model: {
                                        value: item.key,
                                        callback: function ($$v) {
                                          _vm.$set(item, "key", $$v)
                                        },
                                        expression: "item.key",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-plus",
                                      staticStyle: { "font-size": "18px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRow(item, index)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-minus",
                                      staticStyle: { "font-size": "18px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delRow(item, index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-box" }, [
                            _c(
                              "span",
                              { staticStyle: { width: "100px" } },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  },
                                  [_vm._v("方式3：")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._v(
                                  "\n                  使用新内容：\n                  "
                                ),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "520px",
                                    "vertical-align": "top",
                                  },
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 2, maxRows: 2 },
                                    placeholder: "请输入内容",
                                    maxlength: "60",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-box" }, [
                            _c(
                              "span",
                              { staticStyle: { width: "100px" } },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  },
                                  [_vm._v("方式4：")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._v(
                                  "\n                  删除关键字：\n                  "
                                ),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "520px",
                                    "vertical-align": "top",
                                  },
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 6, maxRows: 6 },
                                    placeholder:
                                      "请输入需要删除的关键词、多个关键词用逗号“,”分割，最多10000个字",
                                    maxlength: "10000",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-box" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "padding-top": "6px",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  },
                                  [_vm._v("方式5：")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._v(
                                  "\n                  替换前缀内容：\n                  "
                                ),
                                _c("el-input", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入查找内容",
                                  },
                                }),
                                _vm._v(
                                  "\n                   \n                   \n                  替换后缀内容:\n                  "
                                ),
                                _c("el-input", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入查找内容",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#f01",
                                      "margin-left": "10px",
                                    },
                                  },
                                  [_vm._v("(将替换相应的字数，无需替换则为空)")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "上下架"
                      ? _c(
                          "div",
                          { staticClass: "type-edit" },
                          [
                            _vm._v(
                              "\n              状态选择\n               \n              "
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择上下架状态",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "上架", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "下架", value: "2" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "品牌"
                      ? _c(
                          "div",
                          { staticClass: "type-edit" },
                          [
                            _vm._v(
                              "\n              品牌选择\n               \n              "
                            ),
                            _c("el-input", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                placeholder: "请选择品牌",
                                size: "mini",
                              },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "物流类型"
                      ? _c(
                          "div",
                          { staticClass: "type-edit" },
                          [
                            _vm._v(
                              "\n              物流类型\n               \n              "
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择物流类型",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "上架", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "下架", value: "2" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "货源地"
                      ? _c(
                          "div",
                          { staticClass: "type-edit" },
                          [
                            _vm._v(
                              "\n              货源地\n               \n              "
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择货源地",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "上架", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "下架", value: "2" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "库存"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n                调整范围\n                  \n                "
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("针对全部SKU")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("针对大于0的SKU")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "3" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("针对最低价的SKU")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("使用最新库存")]
                              ),
                              _vm._v("\n                  \n                "),
                              _c("el-input", {
                                staticStyle: {
                                  width: "160px",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  placeholder: "请输入内容",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("新增库存")]
                              ),
                              _vm._v("\n                  \n                "),
                              _c("el-input", {
                                staticStyle: {
                                  width: "150px",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  placeholder: "请输入内容",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                              _vm._v("\n                件\n              "),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("减少库存")]
                              ),
                              _vm._v("\n                  \n                "),
                              _c("el-input", {
                                staticStyle: {
                                  width: "150px",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  placeholder: "请输入内容",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                              _vm._v(
                                "\n                件，且不低于\n                "
                              ),
                              _c("el-input", {
                                staticStyle: {
                                  width: "150px",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  placeholder: "请输入内容",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                              _vm._v("\n                件\n              "),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "图片补全"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n                商品图片补全到：\n                "
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "5张图", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "4张图", value: "2" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#f01" } }, [
                                _vm._v("注意：此操作无法还原，请谨慎操作"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-top": "20px",
                              },
                            },
                            [
                              _c("span", [_vm._v("选取图片范围：")]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.checked,
                                          callback: function ($$v) {
                                            _vm.checked = $$v
                                          },
                                          expression: "checked",
                                        },
                                      },
                                      [_vm._v("从轮播图中随机选取")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.checked,
                                          callback: function ($$v) {
                                            _vm.checked = $$v
                                          },
                                          expression: "checked",
                                        },
                                      },
                                      [_vm._v("从SKU中随机选取")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "#f01",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [_vm._v("支持拖动改变优先级顺序")]
                                ),
                                _vm._v(" "),
                                _c("p"),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "替换商品图片"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-top": "20px",
                              },
                            },
                            [
                              _c("span", [_vm._v("支持修改图片类型：")]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "轮播图，白底图， 长图 ，SKU图，详情图"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "针对主图、详情图替换时，本地图片数量小于商品图片数量："
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [_vm._v("从轮播图中随机选取")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "2" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [_vm._v("从SKU中随机选取")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "#f01",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [_vm._v("支持拖动改变优先级顺序")]
                                ),
                                _vm._v(" "),
                                _c("p"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(0),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "预售时间"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "div",
                            { staticClass: "radio-gap" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("统一改成定时预售")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radio-right-box" },
                                [
                                  _vm._v(
                                    "\n                  统一销售时间\n                    \n                  "
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      size: "mini",
                                      type: "date",
                                      placeholder: "选择日期",
                                    },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "radio-gap" },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "1" },
                                          model: {
                                            value: _vm.value,
                                            callback: function ($$v) {
                                              _vm.value = $$v
                                            },
                                            expression: "value",
                                          },
                                        },
                                        [_vm._v("统一设置承诺发货时间")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请选择货源地",
                                          },
                                          model: {
                                            value: _vm.value,
                                            callback: function ($$v) {
                                              _vm.value = $$v
                                            },
                                            expression: "value",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "48小时",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "24小时",
                                              value: "2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "radio-gap" },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "1" },
                                          model: {
                                            value: _vm.value,
                                            callback: function ($$v) {
                                              _vm.value = $$v
                                            },
                                            expression: "value",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "有承诺发货时间的保持不变，没有承诺发货时间，设置承诺发货时间\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请选择货源地",
                                          },
                                          model: {
                                            value: _vm.value,
                                            callback: function ($$v) {
                                              _vm.value = $$v
                                            },
                                            expression: "value",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "48小时",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "24小时",
                                              value: "2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radio-gap" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("统一改成时段预售")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radio-right-box" },
                                [
                                  _vm._v(
                                    "\n                  支付成功后\n                    \n                  "
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请选择货源地",
                                      },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "3天", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "2天", value: "2" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n                  内发货\n                "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radio-gap" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "3" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("统一改成非预售")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "radio-right-box" }, [
                                _c(
                                  "p",
                                  { staticClass: "radio-gap" },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [_vm._v("统一设置承诺发货时间")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择货源地",
                                        },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "48小时",
                                            value: "1",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "24小时",
                                            value: "2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "radio-gap" },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "有承诺发货时间的保持不变，没有承诺发货时间，设置承诺发货时间\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择货源地",
                                        },
                                        model: {
                                          value: _vm.value,
                                          callback: function ($$v) {
                                            _vm.value = $$v
                                          },
                                          expression: "value",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "48小时",
                                            value: "1",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "24小时",
                                            value: "2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "商品属性"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("面料俗称")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("穿着方式")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("领型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("风格")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("袖长")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("上市时节")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("版型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    display: "inline-block",
                                    "margin-top": "20px",
                                  },
                                },
                                [_vm._v("厚薄")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "删除商品"
                      ? _c(
                          "div",
                          { staticClass: "type-edit" },
                          [
                            _vm._v(
                              "\n              在售商品处理：\n              "
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [_vm._v("遇到在售商品不删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [_vm._v("遇到在售商品也删除")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(
                              "\n              过滤商品：\n              "
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [_vm._v("遇到在售商品不删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [_vm._v("遇到在售商品也删除")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "满件折扣"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("方式1")]
                              ),
                              _vm._v(
                                "\n                满两件\n                "
                              ),
                              _c("el-input-number", {
                                attrs: {
                                  size: "small",
                                  "controls-position": "right",
                                  min: 1,
                                  max: 10,
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                              _vm._v(
                                "\n                折扣\n                "
                              ),
                              _c("span", [
                                _vm._v(
                                  "注：商品满2件折扣与店铺满2件折扣不会叠加"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("方式2")]
                              ),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "关闭满2件折扣。部分商品满2件折扣必填，请选择此类商品的处理方案"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "48小时", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "24小时", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "3" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("方式3")]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("直接设置折扣")]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "48小时", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "24小时", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "运费模板"
                      ? _c(
                          "div",
                          { staticClass: "type-edit" },
                          [
                            _vm._v(
                              "\n              模板选择：\n               \n              "
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择货源地",
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "上架", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "下架", value: "2" },
                                }),
                              ],
                              1
                            ),
                            _vm._v("\n               \n              "),
                            _c(
                              "el-button",
                              { attrs: { size: "mini", type: "primary" } },
                              [_vm._v("同步")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "单买价" ||
                    _vm.editTitle == "团购价" ||
                    _vm.editTitle == "商品参考价"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "_til" }, [
                                _vm._v("修改范围"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("针对全部SKU")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("针对最低价SKU")]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "p",
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1" },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    },
                                    [_vm._v("使用新价格")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticStyle: { width: "140px" },
                                    attrs: { size: "mini" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1" },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    },
                                    [_vm._v("使用公式")]
                                  ),
                                  _vm._v(" "),
                                  _c("div"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editTitle == "是否二手" ||
                    _vm.editTitle == "其他商品信息" ||
                    _vm.editTitle == "承诺发货时间" ||
                    _vm.editTitle == "7天无理由退换货" ||
                    _vm.editTitle == "假一赔十" ||
                    _vm.editTitle == "团购人数" ||
                    _vm.editTitle == "单次限量" ||
                    _vm.editTitle == "限购次数"
                      ? _c("div", { staticClass: "type-edit" }, [
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("是否二手商品")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("二手")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [_vm._v("非二手")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#f01" } }, [
                                _vm._v(
                                  "(注意：选择【二手】提交并发布成功后无法修改为【非二手】)"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("承诺发货时间")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#f01" } }, [
                                _vm._v(
                                  "(注意：不同类目的有效承诺发货时间以拼多多商家后台为准。若选择无效发货时间，拼多多将自动调整为一个有效时间并审核通过。)"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("假一赔十")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("7天无理由")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择货源地",
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "上架", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "下架", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("团购人数")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                attrs: {
                                  size: "small",
                                  "controls-position": "right",
                                  min: 1,
                                  max: 10,
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("单次限量")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                attrs: {
                                  size: "small",
                                  "controls-position": "right",
                                  min: 1,
                                  max: 10,
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "row" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("限购次数")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                attrs: {
                                  size: "small",
                                  "controls-position": "right",
                                  min: 1,
                                  max: 10,
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.goodsData.length > 0
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.pageLoading,
                              expression: "pageLoading",
                            },
                          ],
                          staticClass: "goods-list-box",
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "20px",
                              },
                              attrs: {
                                data: _vm.goodsData,
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  fontWeight: "700",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品信息",
                                  align: "center",
                                  width: "300",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "info-box" },
                                            [
                                              _c("img", {
                                                staticClass: "img-g",
                                                attrs: {
                                                  src: scope.row.goodsPicture,
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "info-txt" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "txt-til" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            scope.row.goodsName
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "g-id" },
                                                    [
                                                      _vm._v(
                                                        "商品ID:\n                        " +
                                                          _vm._s(
                                                            scope.row.goodsId
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4016217446
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "350",
                                  label: "原状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.editTitle == "标题"
                                            ? _c("div", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row.goodsName)
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editTitle == "短标题"
                                            ? _c("div", [
                                                _c("span", [_vm._v("未设置")]),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editTitle == "上下架"
                                            ? _c("div", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.isOnsale == 0
                                                        ? "下架"
                                                        : "上架"
                                                    )
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  361188263
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "350",
                                  label: "调整后状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.editTitle == "标题"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "textarea",
                                                      placeholder: "请输入内容",
                                                      maxlength: "60",
                                                      "show-word-limit": "",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.goodsName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "goodsName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.goodsName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editTitle == "上下架"
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "80%",
                                                      },
                                                      attrs: {
                                                        placeholder: "请选择",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.isOnsale,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "isOnsale",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.isOnsale",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "上架",
                                                          value: "1",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "下架",
                                                          value: "0",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2810320760
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  align: "center",
                                  label: "操作",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delGoods(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("排除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  775569496
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pagetation-box" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "current-page": _vm.currentPage,
                                  "page-size": _vm.limit,
                                  layout: "total, prev, pager, next",
                                  total: _vm.total,
                                },
                                on: {
                                  "update:currentPage": function ($event) {
                                    _vm.currentPage = $event
                                  },
                                  "update:current-page": function ($event) {
                                    _vm.currentPage = $event
                                  },
                                  "current-change": _vm.handleCurrentChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bottom-edit-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 auto", display: "block" },
                        attrs: { type: "primary" },
                        on: { click: _vm.searchHandle },
                      },
                      [_vm._v("预览修改效果\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "20px",
          border: "1px solid #c6bfbf",
          "line-height": "18px",
          padding: "20px",
          background: "rgba(217,221,198,0.2)",
        },
      },
      [
        _c("p", [_vm._v("注意事项：")]),
        _vm._v(" "),
        _c("p", [_vm._v("1.替换文件需以压缩包形式上传。压缩包限制100M。")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "2.压缩包内图片文件命名:“商品id_替换类型_序号”，或“商品id+替换类型+序号"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-left": "20px" } }, [
          _c("p", [
            _vm._v("举例：“343535465465_轮播图_01”或”343535465465+轮播图+01“"),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("“343535465465_白底图“或”343535465465+白底图“")]),
          _vm._v(" "),
          _c("p", [_vm._v("“343535465465_长图”或”343535465465+长图“")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    “343535465465_SKU图_白色（343535465465_SKU图_白色_S”）或”343535465465+SKU图+白色（343535465465+SKU图+白色+S”）"
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("“343535465465_详情图_01”或”343535465465+详情图+01“"),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                  3.若本地SKU规格图只填规格类型（举例：343535465465_SKU图_白色），则替换时对应规格类型（举例：白色）下的SKU规格图都会被替换。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                  4.根据本地图片商品ID匹配店铺商品，图片按照本地图片序号依次替换，若本地图片命名非01开始递增，则按序号由小到大依次按顺序替换商品图片。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.商品或商品修改类型未匹配到本地对应商品ID或修改类型，则商品或对应修改类型跳过不处理。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "6.同个商品ID下的修改类型序号不可重复，若重复则系统随机选择其中一张。"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }