"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['show'],
  data: function data() {
    return {
      activeName: '1',
      linkVal: null,
      linkImgList: [] //链接生成的图片
    };
  },
  methods: {
    getImgList: function getImgList(v) {
      this.linkImgList = v;
    },
    // 关闭弹窗
    closeDialog: function closeDialog() {
      this.$emit('update:show', false);
    }
  }
};