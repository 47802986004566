var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c(
        "div",
        { staticClass: "_container" },
        [
          _c(
            "div",
            { staticClass: "tab-box" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "全部", name: "2" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "在售中", name: "1" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "已下架", name: "0" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "自定义换SKU文字", name: "4" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeName == "3"
            ? _c(
                "div",
                { staticClass: "list-table-box" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.taskListData,
                        border: "",
                        "header-cell-style": { background: "#eef1f6" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "编辑商品数量" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v("\n            拼多多\n          "),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          708537388
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "successCount",
                          label: "提交成功数量",
                          align: "center",
                          width: "140px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "failCount",
                          width: "140px",
                          align: "center",
                          label: "提交失败数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "skipCount",
                          width: "140px",
                          align: "center",
                          label: "任务类型",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          width: "140px",
                          label: "任务创建时间",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "status",
                          width: "140px",
                          align: "center",
                          label: "任务状态",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 0
                                    ? _c("span", [_vm._v("待处理")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 1
                                    ? _c("span", [_vm._v("处理中")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c("span", [_vm._v("已完成")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3054665557
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          label: "操作",
                          align: "center",
                          width: "220",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showtaskHandle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看日志")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          236147284
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.activeName == "4"
            ? _c("div", { staticClass: "list-table-box" }, [
                _c("div", { staticClass: "content-box" }, [
                  _c("div", { staticClass: "content-item" }, [
                    _c("span", { staticClass: "title" }, [_vm._v("SKU文字")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-box" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 13, maxRows: 13 },
                            resize: "none",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: _vm.sku,
                            callback: function ($$v) {
                              _vm.sku = $$v
                            },
                            expression: "sku",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bottom-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.generateSku },
                          },
                          [_vm._v("开始生成\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              color: "red",
                            },
                          },
                          [
                            _vm._v(
                              "预计消耗" +
                                _vm._s(
                                  (
                                    0.03 * _vm.sku.trim().split("\n").length
                                  ).toFixed(2)
                                ) +
                                "个积分"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content-item" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("AI自动生成SKU文字"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text-box",
                        staticStyle: { padding: "5px" },
                      },
                      _vm._l(_vm.geneList, function (item) {
                        return _c(
                          "div",
                          { key: item, staticClass: "item-text" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bottom-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.copy },
                          },
                          [_vm._v("一键复制\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : [
                _c("div", { staticClass: "search-box" }, [
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [_vm._v("店铺")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "custom-select",
                          attrs: {
                            "popper-append-to-body": false,
                            placeholder: "请选择",
                            size: "mini",
                          },
                          on: { change: _vm.storeChange },
                          model: {
                            value: _vm.valueSelect,
                            callback: function ($$v) {
                              _vm.valueSelect = $$v
                            },
                            expression: "valueSelect",
                          },
                        },
                        _vm._l(_vm.optionsSelect, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                disabled: item.onlineStatus == 0,
                                label: item.mallName,
                                value: item.id,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.mallName)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [_vm._v("商品id")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { size: "mini", placeholder: "请输入商品id" },
                        model: {
                          value: _vm.goodsId,
                          callback: function ($$v) {
                            _vm.goodsId = $$v
                          },
                          expression: "goodsId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("发布时间"),
                      ]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "timestamp",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.postTime,
                          callback: function ($$v) {
                            _vm.postTime = $$v
                          },
                          expression: "postTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("运费模板"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.getPostTemplate },
                          model: {
                            value: _vm.templateVal,
                            callback: function ($$v) {
                              _vm.templateVal = $$v
                            },
                            expression: "templateVal",
                          },
                        },
                        _vm._l(_vm.templatePostList, function (item) {
                          return _c("el-option", {
                            key: item.templateId,
                            attrs: {
                              label: item.templateName,
                              size: "mini",
                              value: item.templateId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.searchHandle },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-box" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", "margin-top": "20px" },
                        attrs: {
                          data: _vm.tableData,
                          "header-cell-style": {
                            background: "#eef1f6",
                            fontWeight: "700",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品信息",
                            align: "center",
                            width: "300",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "info-box" }, [
                                    _c("img", {
                                      staticClass: "img-g",
                                      attrs: {
                                        src: scope.row.goodsPicture,
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "info-txt" }, [
                                      _c("div", { staticClass: "txt-til" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(scope.row.goodsName) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "g-id" }, [
                                        _vm._v(
                                          "商品ID:\n                    " +
                                            _vm._s(scope.row.goodsId) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "price",
                            align: "center",
                            width: "250",
                            label: "价格",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "price" }, [
                                    _c("div", { staticClass: "price-box" }, [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "#FF003E",
                                            "line-height": "20px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "拼单价：" +
                                              _vm._s(scope.row.minGroupPrice) +
                                              "-" +
                                              _vm._s(scope.row.maxGroupPrice) +
                                              "￥"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "单买价：" +
                                            _vm._s(
                                              scope.row.minSinglePurchasePrice
                                            ) +
                                            "-" +
                                            _vm._s(
                                              scope.row.maxSinglePurchasePrice
                                            ) +
                                            "￥"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "参考价：" +
                                            _vm._s(scope.row.marketPrice) +
                                            "￥"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "SKU", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.sku) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "状态", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.isOnsale == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("已下架")]
                                      )
                                    : _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("上架中")]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsQuantity",
                            label: "库存",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "presaleWay",
                            label: "是否预售",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "操作",
                            width: "250",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-dropdown",
                                    {
                                      attrs: { trigger: "click" },
                                      on: {
                                        command: function ($event) {
                                          return _vm.edithandle(
                                            $event,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                        },
                                        [
                                          _vm._v("\n                  编辑"),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticStyle: {
                                            height: "60%",
                                            overflow: "auto",
                                          },
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        _vm._l(_vm.editList, function (item) {
                                          return _c(
                                            "el-dropdown-item",
                                            {
                                              key: item.id,
                                              attrs: { command: item },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.title) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  scope.row.isOnsale == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setGoodsStatus(
                                                scope.row,
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                下架\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.isOnsale == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setGoodsStatus(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                上架\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "0" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delHandle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除\n              ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pagetation-box" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-size": _vm.limit,
                        layout: "total, prev, pager, next",
                        total: _vm.total,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.currentPage = $event
                        },
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
          _vm._v(" "),
          _c("imageEdit", {
            ref: "imgEditRef",
            attrs: { show: _vm.imgEditVisible },
            on: {
              "update:show": function ($event) {
                _vm.imgEditVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c("imageInimages", {
            ref: "imageInimagesRef",
            attrs: { show: _vm.imageInimagesVisible },
            on: {
              "update:show": function ($event) {
                _vm.imageInimagesVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.editModalTitle,
                visible: _vm.editDialogVisible,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editDialogVisible = $event
                },
              },
            },
            [
              _vm.editModalType != 6
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      "\n      本功能对商品分类有要求，大部分分类下的商品不支持修改！\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 11
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("是否二手："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.selectedItem.secondHand,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "secondHand", $$v)
                            },
                            expression: "selectedItem.secondHand",
                          },
                        },
                        [_vm._v("非二手")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 10
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("单次限购量："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "number", size: "mini" },
                        model: {
                          value: _vm.selectedItem.orderLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedItem, "orderLimit", $$v)
                          },
                          expression: "selectedItem.orderLimit",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.sameLimit,
                            callback: function ($$v) {
                              _vm.sameLimit = $$v
                            },
                            expression: "sameLimit",
                          },
                        },
                        [_vm._v("同时设置限购")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 12
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("7天无理由退换货："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.selectedItem.isRefundable,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "isRefundable", $$v)
                            },
                            expression: "selectedItem.isRefundable",
                          },
                        },
                        [_vm._v("是")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 13
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("7天无理由退换货："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.isSeven,
                            callback: function ($$v) {
                              _vm.isSeven = $$v
                            },
                            expression: "isSeven",
                          },
                        },
                        [_vm._v("同时设置限购")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 9
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("限购数量："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "number", size: "mini" },
                        model: {
                          value: _vm.selectedItem.buyLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedItem, "buyLimit", $$v)
                          },
                          expression: "selectedItem.buyLimit",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 8
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("假一赔十："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.selectedItem.isFolt,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "isFolt", $$v)
                            },
                            expression: "selectedItem.isFolt",
                          },
                        },
                        [_vm._v("是")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 7
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("运费模板："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "190px" },
                          attrs: { placeholder: "请选择", size: "mini" },
                          model: {
                            value: _vm.selectedItem.costTemplateId,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "costTemplateId", $$v)
                            },
                            expression: "selectedItem.costTemplateId",
                          },
                        },
                        _vm._l(_vm.templatePostList, function (item) {
                          return _c("el-option", {
                            key: item.templateId,
                            attrs: {
                              label: item.templateName,
                              size: "mini",
                              value: item.templateId,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [_vm._v(" 查看或修改")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 6
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.releaseTime,
                            callback: function ($$v) {
                              _vm.releaseTime = $$v
                            },
                            expression: "releaseTime",
                          },
                        },
                        [_vm._v("当日发售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.releaseTime,
                            callback: function ($$v) {
                              _vm.releaseTime = $$v
                            },
                            expression: "releaseTime",
                          },
                        },
                        [_vm._v("24小时")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "3" },
                          model: {
                            value: _vm.releaseTime,
                            callback: function ($$v) {
                              _vm.releaseTime = $$v
                            },
                            expression: "releaseTime",
                          },
                        },
                        [_vm._v("48小时")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 4
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-top": "20px" } }, [
                      _vm._v("满件折扣："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _vm.selectedItem.twoPiecesDiscount != 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "vertical-align": "top",
                                  "margin-right": "40px",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "150px" },
                                  attrs: { type: "number", size: "mini" },
                                  model: {
                                    value: _vm.selectedItem.twoPiecesDiscount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedItem,
                                        "twoPiecesDiscount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedItem.twoPiecesDiscount",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "折后拼单价：" +
                                        _vm._s(
                                          (
                                            (_vm.selectedItem.minGroupPrice *
                                              (_vm.selectedItem
                                                .twoPiecesDiscount /
                                                100) *
                                              100) /
                                            100
                                          ).toFixed(2)
                                        ) +
                                        " ~\n            " +
                                        _vm._s(
                                          (
                                            (_vm.selectedItem.maxGroupPrice *
                                              (_vm.selectedItem
                                                .twoPiecesDiscount /
                                                100) *
                                              100) /
                                            100
                                          ).toFixed(2)
                                        ) +
                                        "元"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("span", { staticStyle: { color: "#f01" } }, [
                              _vm._v("暂无折扣"),
                            ]),
                        _vm._v(
                          "\n\n        注：商品满2件折扣与店铺满2件折扣不会叠加\n        "
                        ),
                        _vm.selectedItem.twoPiecesDiscount != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedItem.twoPiecesDiscount = 0
                                  },
                                },
                              },
                              [_vm._v("取消折扣")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedItem.twoPiecesDiscount = 1
                                  },
                                },
                              },
                              [_vm._v("添加折扣")]
                            ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 5
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("是否预售："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("非预售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("定时预售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("时段预售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 4 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("规格预售")]
                      ),
                      _vm._v(" "),
                      _vm.PreSaleVal == 2 || _vm.PreSaleVal == 3
                        ? _c("p", { staticStyle: { "margin-top": "20px" } }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-top": "20px" } },
                              [_vm._v("预售时间：")]
                            ),
                            _vm._v(" "),
                            _vm.PreSaleVal == 3
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n                 支付成功后\n          "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "110px" },
                                        attrs: {
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        model: {
                                          value: _vm.timeSale,
                                          callback: function ($$v) {
                                            _vm.timeSale = $$v
                                          },
                                          expression: "timeSale",
                                        },
                                      },
                                      _vm._l(15, function (item, i) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item + "天",
                                            size: "mini",
                                            value: item,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                    _vm._v("\n          内发货\n          "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.PreSaleVal == 2
                              ? _c(
                                  "span",
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "timestamp",
                                      },
                                      model: {
                                        value: _vm.preSaleTime,
                                        callback: function ($$v) {
                                          _vm.preSaleTime = $$v
                                        },
                                        expression: "preSaleTime",
                                      },
                                    }),
                                    _vm._v(
                                      "\n             需在2024-11-29 23:59:59前完成发货\n          "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.PreSaleVal == 4
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "1px solid #eee",
                                  padding: "15px 25px",
                                  "margin-top": "20px",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "border-bottom": "1px solid #eee",
                                        "padding-bottom": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { flex: "1" } },
                                            [_vm._v("SKU名称(规格值)")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticStyle: { flex: "1" } },
                                            [_vm._v("预售时间")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.selectedItem.skuList,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass: "row",
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "margin-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { width: "30%" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.spec[0].specName
                                                    ) + "\n                  "
                                                  ),
                                                  item.spec.length > 1
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "/" +
                                                            _vm._s(
                                                              item.spec[1]
                                                                .specName
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { width: "51%" },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      type: "date",
                                                      placeholder: "选择日期",
                                                      "picker-options":
                                                        _vm.pickerOptions,
                                                      "value-format":
                                                        "timestamp",
                                                    },
                                                    model: {
                                                      value:
                                                        item.skuPreSaleTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "skuPreSaleTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.skuPreSaleTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 24
                ? _c(
                    "div",
                    {
                      staticClass: "edit-box",
                      staticStyle: { "margin-top": "20px", display: "flex" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "130px",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("商品主图："),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #eee",
                            padding: "5px",
                          },
                        },
                        [
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "\n            (1. 宽高比例为1:1或3:4； 2. 宽高须超过480px，最多上传10张)\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("imgHandleCom", {
                            ref: "imgHandleCom",
                            attrs: {
                              skulist: _vm.selectedItem.carouselGalleryList,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 1
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid #eee",
                          padding: "15px 25px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #eee",
                                  "padding-bottom": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU名称(规格值)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("拼单价"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("单买价"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectedItem.skuList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "30%" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.spec[0].specName) +
                                                "\n                  "
                                            ),
                                            item.spec.length > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        item.spec[1].specName
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "32%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.price,
                                            callback: function ($$v) {
                                              _vm.$set(item, "price", $$v)
                                            },
                                            expression: "item.price",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "32%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.multiPrice,
                                            callback: function ($$v) {
                                              _vm.$set(item, "multiPrice", $$v)
                                            },
                                            expression: "item.multiPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("参考价")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: {
                                width: "280px",
                                "margin-left": "30px",
                                "margin-top": "20px",
                              },
                              model: {
                                value: _vm.selectedItem.marketPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedItem, "marketPrice", $$v)
                                },
                                expression: "selectedItem.marketPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 2
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid #eee",
                          padding: "15px 25px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #eee",
                                  "padding-bottom": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU名称(规格值)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("拼单价"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("单买价"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectedItem.skuList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "30%" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.spec[0].specName) +
                                                "\n                    "
                                            ),
                                            item.spec.length > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        item.spec[1].specName
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "32%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.price,
                                            callback: function ($$v) {
                                              _vm.$set(item, "price", $$v)
                                            },
                                            expression: "item.price",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "32%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.multiPrice,
                                            callback: function ($$v) {
                                              _vm.$set(item, "multiPrice", $$v)
                                            },
                                            expression: "item.multiPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("参考价")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: {
                                width: "280px",
                                "margin-left": "30px",
                                "margin-top": "20px",
                              },
                              model: {
                                value: _vm.selectedItem.marketPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedItem, "marketPrice", $$v)
                                },
                                expression: "selectedItem.marketPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 3
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid #eee",
                          padding: "15px 25px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #eee",
                                  "padding-bottom": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU名称(规格值)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU编码"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectedItem.skuList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "30%" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.spec[0].specName) +
                                                "\n                  "
                                            ),
                                            item.spec.length > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        item.spec[1].specName
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "51%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.outSkuSn,
                                            callback: function ($$v) {
                                              _vm.$set(item, "outSkuSn", $$v)
                                            },
                                            expression: "item.outSkuSn",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("商品编码")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: {
                                width: "280px",
                                "margin-left": "30px",
                                "margin-top": "20px",
                              },
                              model: {
                                value: _vm.selectedItem.outerGoodsId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedItem,
                                    "outerGoodsId",
                                    $$v
                                  )
                                },
                                expression: "selectedItem.outerGoodsId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 25
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("商品标题："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "650px" },
                        attrs: {
                          size: "mini",
                          "show-word-limit": "",
                          maxlength: "60",
                        },
                        model: {
                          value: _vm.selectedItem.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedItem, "goodsName", $$v)
                          },
                          expression: "selectedItem.goodsName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 26
                ? _c(
                    "div",
                    {
                      staticClass: "edit-box",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v("商品标题："),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { flex: "1" } }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#0C8AFF",
                              "margin-bottom": "20px",
                            },
                          },
                          [_vm._v("其他属性")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "options-box",
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(_vm.selectedItem.catList, function (item) {
                            return _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-bottom": "10px",
                                  "margin-right": "10px",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: { change: _vm.catListSelectedChange },
                                    model: {
                                      value: item.catListSelected,
                                      callback: function ($$v) {
                                        _vm.$set(item, "catListSelected", $$v)
                                      },
                                      expression: "item.catListSelected",
                                    },
                                  },
                                  _vm._l(item.values, function (v) {
                                    return _c("el-option", {
                                      key: v.vid,
                                      attrs: { label: v.value, value: v.vid },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 28
                ? _c(
                    "div",
                    {
                      staticClass: "edit-box",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v("商品详情图："),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-img-box" }, [
                        _c(
                          "div",
                          { staticClass: "detail-left" },
                          [
                            _c(
                              "p",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [_vm._v("页面预览总高度472px")]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.selectedItem.detailGalleryList,
                              function (item) {
                                return _c("img", {
                                  staticClass: "img-l",
                                  attrs: { src: item, alt: "" },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "detail-item-right" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _vm._v(
                                  "(图片宽度应为480-1200px，高度应为1500px以内,最多上传50张)"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("imgHandleCom", {
                              ref: "imgHandleCom",
                              attrs: {
                                skulist: _vm.selectedItem.detailGalleryList,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.editDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("关 闭")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitHandle },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }