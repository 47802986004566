var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c(
        "div",
        { staticClass: "_container" },
        [
          _c(
            "div",
            { staticClass: "tab-box" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.tabActiveName,
                    callback: function ($$v) {
                      _vm.tabActiveName = $$v
                    },
                    expression: "tabActiveName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "商品复制", name: "first" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "任务列表", name: "second" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.tabActiveName == "second"
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.taskListData,
                        border: "",
                        height: "calc(100vh - 200px)",
                        "header-cell-style": { background: "#eef1f6" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "商品来源店铺" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              拼多多\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4114760748
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "successCount",
                          label: "复制成功数量",
                          align: "center",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "failCount",
                          width: "110px",
                          align: "center",
                          label: "复制失败数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "skipCount",
                          width: "110px",
                          align: "center",
                          label: "跳过商品数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          width: "110px",
                          label: "任务创建时间",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "status",
                          width: "110px",
                          align: "center",
                          label: "任务状态",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 0
                                    ? _c("span", [_vm._v("待处理")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 1
                                    ? _c("span", [_vm._v("处理中")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c("span", [_vm._v("已完成")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3054665557
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          label: "操作",
                          align: "center",
                          width: "220",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showtaskHandle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看日志")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          236147284
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPageRecord,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.limitRecord,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.totalRecord,
                    },
                    on: {
                      "size-change": _vm.handleSizeRecordChange,
                      "current-change": _vm.handleCurrentRecordChange,
                    },
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "copy-box" }, [
                _c("div", { staticClass: "bottom-table-box" }, [
                  _c(
                    "div",
                    { staticClass: "options-row-top" },
                    [
                      _vm._v(
                        "\n            选择复制店铺：\n                        "
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass: "custom-select",
                          staticStyle: { width: "150px" },
                          attrs: {
                            "popper-append-to-body": false,
                            placeholder: "请选择",
                            size: "mini",
                          },
                          on: { change: _vm.storeChange1 },
                          model: {
                            value: _vm.authCode,
                            callback: function ($$v) {
                              _vm.authCode = $$v
                            },
                            expression: "authCode",
                          },
                        },
                        _vm._l(_vm.optionsSelect, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                disabled: item.onlineStatus == 0,
                                label: item.mallName,
                                value: item.authCode,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.mallName)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm._v(
                        "\n                        发布时间：\n                        "
                      ),
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "timestamp",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.postTime,
                          callback: function ($$v) {
                            _vm.postTime = $$v
                          },
                          expression: "postTime",
                        },
                      }),
                      _vm._v(
                        "\n                          \n                        "
                      ),
                      _vm._v(" "),
                      _vm._v(
                        "\n                        商品名称：\n                        "
                      ),
                      _c("el-input", {
                        staticStyle: { width: "12%" },
                        attrs: { size: "mini", placeholder: "请输入商品名称" },
                        model: {
                          value: _vm.goodsName,
                          callback: function ($$v) {
                            _vm.goodsName = $$v
                          },
                          expression: "goodsName",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.searchHandle },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openSet },
                        },
                        [_vm._v("铺货设置")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row-bottom" },
                    [
                      _vm._v("\n            选择复制到店铺：\n            "),
                      _c(
                        "el-select",
                        {
                          staticClass: "custom-select",
                          staticStyle: { width: "150px" },
                          attrs: {
                            "popper-append-to-body": false,
                            placeholder: "请选择",
                            size: "mini",
                          },
                          on: { change: _vm.storeChange },
                          model: {
                            value: _vm.valueSelect,
                            callback: function ($$v) {
                              _vm.valueSelect = $$v
                            },
                            expression: "valueSelect",
                          },
                        },
                        _vm._l(_vm.optionsSelect, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                disabled: item.onlineStatus == 0,
                                label: item.mallName,
                                value: item.authCode,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.mallName)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        { attrs: { size: "mini" }, on: { click: _vm.copyNow } },
                        [_vm._v("立刻复制")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.copyAllList },
                        },
                        [_vm._v("整店复制")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.goodsList,
                            "header-cell-style": { background: "#eef1f6" },
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "80" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              align: "center",
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "date", label: "商品信息" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "info-box" }, [
                                      _c("img", {
                                        staticClass: "img-g",
                                        attrs: {
                                          src: scope.row.goodsPicture,
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info-txt" }, [
                                        _c("div", { staticClass: "txt-til" }, [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(scope.row.goodsName) +
                                              "\n                      "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "g-id" }, [
                                          _vm._v(
                                            "商品ID:\n                        " +
                                              _vm._s(scope.row.goodsId) +
                                              "\n                      "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "商品链接" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                  https://mobile.yangkeduo.com/goods.html?goods_id= " +
                                        _vm._s(scope.row.goodsId) +
                                        "\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("taskLogDialog", {
            attrs: { data: _vm.taskDetailListData, show: _vm.taskLogVisible },
            on: {
              "update:show": function ($event) {
                _vm.taskLogVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c("openSetDialog", {
            ref: "openSetDialogRef",
            attrs: { showSet: _vm.openSetVisible },
            on: {
              "update:showSet": function ($event) {
                _vm.openSetVisible = $event
              },
              "update:show-set": function ($event) {
                _vm.openSetVisible = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }