var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "split-accord-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "任务日志", width: "80%", visible: _vm.show },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "task-log-box" },
            _vm._l(_vm.data, function (item) {
              return _c("div", { staticClass: "box-content" }, [
                _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "https://img.pddpic.com/open-gw/2024-05-29/0248d125036fff12693a087565b16beb.jpeg",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "right-content" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(
                      "\n            ins风冰川纹玻璃杯子水杯女夏季啤酒杯家用牛奶茶杯男饮料咖啡杯\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("商品ID:618594242687")]),
                  _vm._v("\n              \n          "),
                  _c("span", [_vm._v("  复制状态：成功    ")]),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPageRecord,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.limitRecord,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalRecord,
            },
            on: {
              "size-change": _vm.handleSizeRecordChange,
              "current-change": _vm.handleCurrentRecordChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }