"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _imgHandleCom = _interopRequireDefault(require("@/views/goodsManage/goodsSplit/components/imgHandleCom.vue"));
var _skuBox = _interopRequireDefault(require("@/views/goodsManage/goodsSplit/components/skuBox.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    imgHandleCom: _imgHandleCom.default,
    skuBox: _skuBox.default
  },
  data: function data() {
    return {
      stepActive: 2,
      //步骤条
      goodsSkuList: [],
      goodsInfo: {},
      //商品信息
      tabRadio: '1',
      titleTxt: ''
    };
  },
  created: function created() {},
  watch: {},
  methods: {
    radioInput: function radioInput(v) {
      var _this = this;
      setTimeout(function () {
        console.log(v, 234234234, _this.$refs.skuBoxRef[0].changeSKUTitle());
      }, 200);
      if (v == 2) {}
    },
    deleteSplit: function deleteSplit(i) {
      this.goodsSkuList.splice(i, 1);
    },
    // 修改步骤条
    changeStep: function changeStep(id, n) {
      var c = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var item = this.goodsSkuList.find(function (el) {
        return el.id == id;
      });
      console.log(item, 'changeStep');
      item.stepActive = n;
      item.stepActiveStatus = c;
    },
    changeNum: function changeNum(v) {
      console.log(v, 'changeNum--');
      this.goodsSkuList = [];
      v.info.skuList.forEach(function (el, _index, arr) {
        if (v.info.myfirstPic.length != 0) {
          el.thumbUrl = v.info.myfirstPic[_index] ? v.info.myfirstPic[_index] : el.thumbUrl;
        }
        // if (v.info.excelData.length != 0) {
        //   el.specSku = v.info.excelData[_index] ? v.info.excelData[_index].val[0] : el.specSku
        // }
        // el.specIdList =JSON.stringify([(el.spec.map(v=>v.specId)).join(',')])
        el.specIdList = JSON.stringify(el.spec.map(function (v) {
          return v.specId;
        }));
        el.specList = JSON.parse(JSON.stringify(v.info.specList));
        el.specListCopy = JSON.parse(JSON.stringify(v.info.specList)); //复制出来的商品，用于回复
        v.info.specList.forEach(function (item, i) {
          var _val = Object.keys(item)[0];
          item[_val].forEach(function (sku, index) {
            el.specSku = sku;
          });
        });
      });
      for (var i = 0; i < v.num; i++) {
        var obj = _objectSpread(_objectSpread({
          id: i + 1,
          stepActive: 1,
          checked: false,
          stepActiveStatus: true,
          tabRadio: '1'
        }, v.info), {}, {
          specList: JSON.parse(JSON.stringify(v.info.specList)),
          skuList: JSON.parse(JSON.stringify(v.info.skuList)),
          carouselGalleryList: _toConsumableArray(v.info.carouselGalleryList),
          radioOptions: [{
            label: '1',
            titl: '基本信息'
          }, {
            label: '2',
            titl: 'SKU信息'
          }]
        });
        // 修改标题
        if (v.info.excelData.length != 0) {
          obj.goodsName = v.info.excelData[i] ? v.info.excelData[i].val[0] : obj.goodsName;
        }
        this.goodsSkuList.push(obj);
      }
      console.log(this.goodsSkuList, 'this.goodsSkuList---changeNum');
      this.$forceUpdate();
    }
  }
};