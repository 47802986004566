var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "_container" },
      [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c("span", { staticClass: "toptile" }, [_vm._v("爆款裂变")]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "drap-btn",
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.closeDialog },
              },
              [_vm._v("回退")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "wrapper-box" }, [
          _c("div", { staticClass: "border-box-top" }, [
            _c("div", { staticClass: "top-bg" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "content-box" }, [
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        staticStyle: { "margin-right": "20px" },
                      },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("裂变到当前店铺："),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          staticStyle: { width: "140px" },
                          attrs: {
                            disabled: "",
                            size: "mini",
                            placeholder: "请选择视频",
                          },
                          model: {
                            value: _vm.getShop,
                            callback: function ($$v) {
                              _vm.getShop = $$v
                            },
                            expression: "getShop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        staticStyle: { "margin-right": "20px" },
                      },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("多店裂变："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "130px" },
                            attrs: {
                              "popper-append-to-body": false,
                              placeholder: "请选择",
                              size: "mini",
                            },
                            on: { change: _vm.storeChange },
                            model: {
                              value: _vm.valueSelect,
                              callback: function ($$v) {
                                _vm.valueSelect = $$v
                              },
                              expression: "valueSelect",
                            },
                          },
                          _vm._l(_vm.optionsSelect, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  disabled: item.onlineStatus == 0,
                                  label: item.mallName,
                                  value: item.id,
                                },
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.mallName)),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("运费模板："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "130px" },
                            attrs: { size: "mini", placeholder: "请选择" },
                            on: { change: _vm.getPostTemplate },
                            model: {
                              value: _vm.templateVal,
                              callback: function ($$v) {
                                _vm.templateVal = $$v
                              },
                              expression: "templateVal",
                            },
                          },
                          _vm._l(_vm.templatePostList, function (item) {
                            return _c("el-option", {
                              key: item.templateId,
                              attrs: {
                                label: item.templateName,
                                size: "mini",
                                value: item.templateId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item", staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "primary", size: "mini", round: "" },
                            on: { click: _vm.chooseGoods },
                          },
                          [
                            _vm._v(
                              "\n                  选择机会商品\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.opptunityObj.passId
                          ? _c("div", { staticClass: "op-goods-box" }, [
                              _c("img", {
                                staticClass: "op-img",
                                attrs: {
                                  src: _vm.opptunityObj.selectedChanceGoods
                                    .pic_url,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "op-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.opptunityObj.selectedChanceGoods
                                      .keywords
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("上传商品讲解视频："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "140px", "margin-right": "10px" },
                        attrs: {
                          disabled: "",
                          size: "mini",
                          placeholder: "请选择视频",
                        },
                        on: { change: _vm.inputHandle },
                        model: {
                          value: _vm.splitNumber,
                          callback: function ($$v) {
                            _vm.splitNumber = $$v
                          },
                          expression: "splitNumber",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                round: "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.upLoadVideo($event)
                                },
                              },
                            },
                            [_vm._v("提交视频")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            ref: "fileInputsVideo",
                            staticStyle: {
                              display: "none",
                              border: "1px solid red",
                            },
                            attrs: { type: "file", accept: "video/*" },
                            on: { change: _vm.handleFileUploadVideo },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n                 \n                "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#FF7418",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("审核中")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#FF7418",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("待审核")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#FF2727",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("审核失败")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#026BFF",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("审核成功")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("裂变商品数："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { size: "mini", placeholder: "请输入商品数量" },
                        on: { change: _vm.inputHandle },
                        model: {
                          value: _vm.splitNumber,
                          callback: function ($$v) {
                            _vm.splitNumber = $$v
                          },
                          expression: "splitNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("第一张主图："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.mainPicture,
                            callback: function ($$v) {
                              _vm.mainPicture = $$v
                            },
                            expression: "mainPicture",
                          },
                        },
                        [_vm._v("原主图")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.mainPicture,
                            callback: function ($$v) {
                              _vm.mainPicture = $$v
                            },
                            expression: "mainPicture",
                          },
                        },
                        [_vm._v("自定义换图")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.mainPicture == 1,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.upLoadImgHandle($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                  本地上传\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.uploadNum != 0
                        ? [
                            _c("span", [
                              _vm._v("已选择" + _vm._s(_vm.uploadNum) + "个"),
                            ]),
                            _vm._v(","),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#0a6aa1",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.showImage($event)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("裂变标题设置："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.orPic,
                              callback: function ($$v) {
                                _vm.orPic = $$v
                              },
                              expression: "orPic",
                            },
                          },
                          [_vm._v("原标题")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value: _vm.orPic,
                              callback: function ($$v) {
                                _vm.orPic = $$v
                              },
                              expression: "orPic",
                            },
                          },
                          [_vm._v("自定义换标题")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  disabled: _vm.orPic == 1,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.upLoadTitle($event)
                                  },
                                },
                              },
                              [_vm._v("本地上传\n                  ")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              ref: "fileInputs",
                              staticStyle: { display: "none" },
                              attrs: { type: "file", accept: ".xlsx, .xls" },
                              on: { change: _vm.handleFileUpload },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.excelData.length > 0
                          ? [
                              _c("span", [
                                _vm._v(
                                  "已选择" + _vm._s(_vm.excelData.length) + "个"
                                ),
                              ]),
                              _vm._v(","),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#0a6aa1",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.titleBoxVisible = true
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("SKU文字设置："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.skuText,
                              callback: function ($$v) {
                                _vm.skuText = $$v
                              },
                              expression: "skuText",
                            },
                          },
                          [_vm._v("原文字")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value: _vm.skuText,
                              callback: function ($$v) {
                                _vm.skuText = $$v
                              },
                              expression: "skuText",
                            },
                          },
                          [_vm._v("纯净版")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "3" },
                            model: {
                              value: _vm.skuText,
                              callback: function ($$v) {
                                _vm.skuText = $$v
                              },
                              expression: "skuText",
                            },
                          },
                          [_vm._v("AI換SKU文字")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "4" },
                            model: {
                              value: _vm.skuText,
                              callback: function ($$v) {
                                _vm.skuText = $$v
                              },
                              expression: "skuText",
                            },
                          },
                          [
                            _c("span", [_vm._v("统一増加")]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "88px" },
                                attrs: {
                                  size: "mini",
                                  disabled: _vm.skuText != 4,
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.acModel,
                                  callback: function ($$v) {
                                    _vm.acModel = $$v
                                  },
                                  expression: "acModel",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "最前面", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "最后面", value: "2" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "50px" },
                              attrs: {
                                size: "mini",
                                disabled: _vm.skuText != 4,
                              },
                              model: {
                                value: _vm.skuFixTitle,
                                callback: function ($$v) {
                                  _vm.skuFixTitle = $$v
                                },
                                expression: "skuFixTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("SKU价格设置："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.skuPrice,
                            callback: function ($$v) {
                              _vm.skuPrice = $$v
                            },
                            expression: "skuPrice",
                          },
                        },
                        [_vm._v("原价格")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.skuPrice,
                            callback: function ($$v) {
                              _vm.skuPrice = $$v
                            },
                            expression: "skuPrice",
                          },
                        },
                        [
                          _c("span", [_vm._v(" 原价增减随机值，范围")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              size: "mini",
                              disabled: _vm.skuPrice != 2,
                            },
                            model: {
                              value: _vm.skuPriceVal1,
                              callback: function ($$v) {
                                _vm.skuPriceVal1 = $$v
                              },
                              expression: "skuPriceVal1",
                            },
                          }),
                          _vm._v("\n                  -\n                  "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              size: "mini",
                              disabled: _vm.skuPrice != 2,
                            },
                            model: {
                              value: _vm.skuPriceVal2,
                              callback: function ($$v) {
                                _vm.skuPriceVal2 = $$v
                              },
                              expression: "skuPriceVal2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("商品发布模式："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            on: { input: _vm.radioInput },
                            model: {
                              value: _vm.releaseMode,
                              callback: function ($$v) {
                                _vm.releaseMode = $$v
                              },
                              expression: "releaseMode",
                            },
                          },
                          [_vm._v("立即上架")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            on: { input: _vm.radioInput },
                            model: {
                              value: _vm.releaseMode,
                              callback: function ($$v) {
                                _vm.releaseMode = $$v
                              },
                              expression: "releaseMode",
                            },
                          },
                          [_vm._v("放入已下架")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "3" },
                            on: { input: _vm.radioInput },
                            model: {
                              value: _vm.releaseMode,
                              callback: function ($$v) {
                                _vm.releaseMode = $$v
                              },
                              expression: "releaseMode",
                            },
                          },
                          [_vm._v("放入草稿箱")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tip" },
                  [
                    _c("span", [
                      _vm._v(
                        "\n     温馨提示：填负数则为减价格，填正数则为原价格。AI换标题、水印等功能将消耗算力\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary", round: "" },
                        on: { click: _vm.preViewHandle },
                      },
                      [_vm._v("预览\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#9EDAFF",
                          color: "#454545",
                          border: "none",
                        },
                        attrs: { size: "mini", type: "info", round: "" },
                        on: { click: _vm.startSplit },
                      },
                      [_vm._v("开始铺货\n              ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bg-box" },
            [
              _c("div", { staticClass: "goods-info-box" }, [
                _c("div", { staticClass: "info-g" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text" },
                          on: { click: _vm.checkAll },
                        },
                        [_vm._v("全选")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text" },
                          on: { click: _vm.checkAllNo },
                        },
                        [_vm._v("反选")]
                      ),
                      _vm._v("\n              产品信息\n            "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-box" }, [
                    _c("img", {
                      staticClass: "img-g",
                      attrs: { src: _vm.detailInfo.goodsPicture, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-txt" }, [
                      _c("div", { staticClass: "txt-til" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.detailInfo.goodsName) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "g-id" }, [
                        _vm._v(
                          "商品ID:\n                  " +
                            _vm._s(_vm.detailInfo.goodsId) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("价格")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-box" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          color: "#FF003E",
                          "line-height": "20px",
                        },
                      },
                      [
                        _vm._v(
                          "拼单价：" +
                            _vm._s(_vm.detailInfo.minGroupPrice) +
                            "-" +
                            _vm._s(_vm.detailInfo.maxGroupPrice) +
                            "￥"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "单买价：" +
                          _vm._s(_vm.detailInfo.minSinglePurchasePrice) +
                          "-" +
                          _vm._s(_vm.detailInfo.maxSinglePurchasePrice) +
                          "￥"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "参考价：" + _vm._s(_vm.detailInfo.marketPrice) + "￥"
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sku-info" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("SKU")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sku-info" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.detailInfo.sku) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pre-sale" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("是否预售")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sku-info" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.detailInfo.presaleWay) +
                        "\n            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.isShowPreview
                ? _c("splitEditInfo", { ref: "splitEditInfos" })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("uploadImage", {
          ref: "uploadImageRef",
          attrs: { show: _vm.uploadImageVisible },
          on: {
            "update:show": function ($event) {
              _vm.uploadImageVisible = $event
            },
          },
        }),
        _vm._v(" "),
        _c("showDialog", {
          ref: "showDialogRef",
          attrs: { show: _vm.isShowDialog },
          on: {
            "update:show": function ($event) {
              _vm.isShowDialog = $event
            },
          },
        }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "查看",
              width: "50%",
              visible: _vm.titleBoxVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.titleBoxVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "up-img-box" }, [
              _c("div", { staticClass: "tip-box" }, [
                _c("p", [_vm._v("当前裂变商品数 23，已选3 张主图")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    " 如已选主图数小于裂变商品数，剩下的裂变商品会取原商品的第一张主图"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "如已选主图数大于裂变商品数，船长会自动截取裂变商品对应数量的主图"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "img-box" },
                _vm._l(_vm.excelData, function (item, i) {
                  return _c(
                    "div",
                    { staticClass: "img-item" },
                    [
                      _c("span", [_vm._v("裂变标题" + _vm._s(i + 1))]),
                      _vm._v(":\n            "),
                      _c("el-input", {
                        staticStyle: { width: "340px" },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入商品数量",
                          "show-word-limit": "",
                          maxlength: "60",
                          clearable: "",
                        },
                        model: {
                          value: item.val,
                          callback: function ($$v) {
                            _vm.$set(item, "val", $$v)
                          },
                          expression: "item.val",
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-delete",
                        on: {
                          click: function ($event) {
                            return _vm.delTitle(i)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.titleBoxVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.titleBoxVisible = false
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("p", { staticClass: "txt" }, [
        _vm._v(
          "1.爆款裂变功能的使用会消耗次数，裂变1个商品将消耗1个次数，\n              "
        ),
        _c("strong", { staticStyle: { color: "#065af8" } }, [
          _vm._v("剩余：98258次"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "txt" }, [
        _vm._v(
          "\n              2.裂变无法保证百分之百裂变成功，但都会消耗算力，裂变失败的商品将会放入草稿箱，可以稍做作修改然后上架，少数商品可能出现裂变失败并且无法放入草稿箱，敬请谅解。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }