var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addImg" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "上传图片", name: "first" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "从主图选取", name: "second" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "从SKU选取", name: "third" } }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "从详情图选取", name: "fourth" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeName === "first"
        ? _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo mr10 mb15",
                  attrs: {
                    action: "",
                    headers: _vm.myHeaders,
                    "before-upload": _vm.beforeUpload,
                    "show-file-list": false,
                    multiple: "",
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "second"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "imgInfo" },
              _vm._l(_vm.masterImg, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "imgItem",
                    on: {
                      dblclick: function ($event) {
                        return _vm.handleImgChange(index, item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { height: "100%", width: "100%" },
                      attrs: { src: item, alt: "" },
                    }),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "third"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "imgInfo" },
              _vm._l(_vm.skuImg, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "imgItem",
                    on: {
                      dblclick: function ($event) {
                        return _vm.handleImgChange(index, item.thumbUrl)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { height: "100%", width: "100%" },
                      attrs: { src: item.thumbUrl, alt: "" },
                    }),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "fourth"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "imgInfo" },
              _vm._l(_vm.detailImg, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "imgItem",
                    on: {
                      dblclick: function ($event) {
                        return _vm.handleImgChange(index, item.url)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "100%",
                        width: "100%",
                        "object-fit": "contain",
                      },
                      attrs: { src: item.url, alt: "" },
                    }),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }