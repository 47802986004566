var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "铺货设置", visible: _vm.showSet, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.showSet = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "openset-content-box" }, [
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      运费模板：\n      "),
            _c(
              "el-select",
              {
                staticStyle: { width: "300px" },
                attrs: { size: "mini", placeholder: "请选择" },
                model: {
                  value: _vm.templateVal,
                  callback: function ($$v) {
                    _vm.templateVal = $$v
                  },
                  expression: "templateVal",
                },
              },
              _vm._l(_vm.templatePostList, function (item) {
                return _c("el-option", {
                  key: item.templateId,
                  attrs: {
                    label: item.templateName,
                    size: "mini",
                    value: item.templateId,
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.getPostTemplate } },
              [_vm._v("运费模板刷新")]
            ),
            _vm._v("\n       \n       \n      假一赔十：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.oneTen,
                  callback: function ($$v) {
                    _vm.oneTen = $$v
                  },
                  expression: "oneTen",
                },
              },
              [_vm._v("否")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.oneTen,
                  callback: function ($$v) {
                    _vm.oneTen = $$v
                  },
                  expression: "oneTen",
                },
              },
              [_vm._v("是")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      售后服务：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.sevenDay,
                  callback: function ($$v) {
                    _vm.sevenDay = $$v
                  },
                  expression: "sevenDay",
                },
              },
              [_vm._v("支持7天无里有退货")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.sevenDay,
                  callback: function ($$v) {
                    _vm.sevenDay = $$v
                  },
                  expression: "sevenDay",
                },
              },
              [_vm._v("不支持7天无里有退货")]
            ),
            _vm._v("\n                          \n      是否二手：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.secondHand,
                  callback: function ($$v) {
                    _vm.secondHand = $$v
                  },
                  expression: "secondHand",
                },
              },
              [_vm._v("否")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.secondHand,
                  callback: function ($$v) {
                    _vm.secondHand = $$v
                  },
                  expression: "secondHand",
                },
              },
              [_vm._v("是")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      发布模式：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.releaseMode,
                  callback: function ($$v) {
                    _vm.releaseMode = $$v
                  },
                  expression: "releaseMode",
                },
              },
              [_vm._v("立即上架")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.releaseMode,
                  callback: function ($$v) {
                    _vm.releaseMode = $$v
                  },
                  expression: "releaseMode",
                },
              },
              [_vm._v("放入已下架")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "3" },
                model: {
                  value: _vm.releaseMode,
                  callback: function ($$v) {
                    _vm.releaseMode = $$v
                  },
                  expression: "releaseMode",
                },
              },
              [_vm._v("放入草稿箱")]
            ),
            _vm._v(
              "\n                                \n      发货时间：\n      "
            ),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.deliveryTime,
                  callback: function ($$v) {
                    _vm.deliveryTime = $$v
                  },
                  expression: "deliveryTime",
                },
              },
              [_vm._v("24小时")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.deliveryTime,
                  callback: function ($$v) {
                    _vm.deliveryTime = $$v
                  },
                  expression: "deliveryTime",
                },
              },
              [_vm._v("48小时")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box", staticStyle: { display: "flex" } },
          [
            _c("span", [_vm._v("每人限购：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "limit-bought" },
              [
                _c("el-input", {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.personCount,
                    callback: function ($$v) {
                      _vm.personCount = $$v
                    },
                    expression: "personCount",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row-box" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "row-left box-common" }, [
              _c("span", [_vm._v("标题设置：")]),
              _vm._v(" "),
              _c("div", { staticClass: "limit-bought" }, [
                _c(
                  "div",
                  { staticClass: "add-fix-box" },
                  [
                    _vm._v(
                      "\n              添加前缀\n                \n              "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.goodsTitle.addPreFix,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsTitle, "addPreFix", $$v)
                        },
                        expression: "goodsTitle.addPreFix",
                      },
                    }),
                    _vm._v(
                      "\n               \n              添加后缀\n              "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.goodsTitle.addBackFix,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsTitle, "addBackFix", $$v)
                        },
                        expression: "goodsTitle.addBackFix",
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n              删除关键字\n              "),
                    _c("el-input", {
                      staticStyle: { width: "290px", margin: "10px 0" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.goodsTitle.delKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsTitle, "delKey", $$v)
                        },
                        expression: "goodsTitle.delKey",
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n              替换关键字\n              "),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.goodsTitle.externalKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsTitle, "externalKey", $$v)
                        },
                        expression: "goodsTitle.externalKey",
                      },
                    }),
                    _vm._v(
                      "\n               \n               \n              替换为\n              "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.goodsTitle.externalKeyTo,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsTitle, "externalKeyTo", $$v)
                        },
                        expression: "goodsTitle.externalKeyTo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row-right box-common" }, [
              _c("span", { staticStyle: { "margin-left": "70px" } }, [
                _vm._v("SKU设置："),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "limit-bought" }, [
                _c(
                  "div",
                  { staticClass: "add-fix-box" },
                  [
                    _vm._v(
                      "\n              添加前缀\n                \n              "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.skuTitle.addPreFix,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuTitle, "addPreFix", $$v)
                        },
                        expression: "skuTitle.addPreFix",
                      },
                    }),
                    _vm._v(
                      "\n               \n              添加后缀\n              "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.skuTitle.addBackFix,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuTitle, "addBackFix", $$v)
                        },
                        expression: "skuTitle.addBackFix",
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n              删除关键字\n              "),
                    _c("el-input", {
                      staticStyle: { width: "290px", margin: "10px 0" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.skuTitle.delKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuTitle, "delKey", $$v)
                        },
                        expression: "skuTitle.delKey",
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n              替换关键字\n              "),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.skuTitle.externalKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuTitle, "externalKey", $$v)
                        },
                        expression: "skuTitle.externalKey",
                      },
                    }),
                    _vm._v(
                      "\n               \n               \n              替换为\n              "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "110px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.skuTitle.externalKeyTo,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuTitle, "externalKeyTo", $$v)
                        },
                        expression: "skuTitle.externalKeyTo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-box " },
            [
              _vm._v("\n        当标题超过60个字符时：\n        "),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.moreTile,
                    callback: function ($$v) {
                      _vm.moreTile = $$v
                    },
                    expression: "moreTile",
                  },
                },
                [_vm._v("不操作")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.moreTile,
                    callback: function ($$v) {
                      _vm.moreTile = $$v
                    },
                    expression: "moreTile",
                  },
                },
                [_vm._v("删除前面的")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "3" },
                  model: {
                    value: _vm.moreTile,
                    callback: function ($$v) {
                      _vm.moreTile = $$v
                    },
                    expression: "moreTile",
                  },
                },
                [_vm._v("删除后面的")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box", staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              { staticClass: "row-left", staticStyle: { display: "flex" } },
              [
                _c("span", [_vm._v("编码设置：")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "limit-bought",
                    staticStyle: { width: "300px" },
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { label: "1" },
                        model: {
                          value: _vm.codeSetting,
                          callback: function ($$v) {
                            _vm.codeSetting = $$v
                          },
                          expression: "codeSetting",
                        },
                      },
                      [_vm._v("不复制商品编码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "2" },
                        model: {
                          value: _vm.codeSetting,
                          callback: function ($$v) {
                            _vm.codeSetting = $$v
                          },
                          expression: "codeSetting",
                        },
                      },
                      [_vm._v("复制商品编码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "3" },
                        model: {
                          value: _vm.codeSetting,
                          callback: function ($$v) {
                            _vm.codeSetting = $$v
                          },
                          expression: "codeSetting",
                        },
                      },
                      [_vm._v("不复制SKU编码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "4" },
                        model: {
                          value: _vm.codeSetting,
                          callback: function ($$v) {
                            _vm.codeSetting = $$v
                          },
                          expression: "codeSetting",
                        },
                      },
                      [_vm._v("复制SKU编码")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row-right", staticStyle: { display: "flex" } },
              [
                _vm._v("\n                                   \n        "),
                _c("span", [_vm._v("去重设置：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "limit-bought" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "1" },
                        model: {
                          value: _vm.duplicateRemoval,
                          callback: function ($$v) {
                            _vm.duplicateRemoval = $$v
                          },
                          expression: "duplicateRemoval",
                        },
                      },
                      [_vm._v("不设置")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "2" },
                        model: {
                          value: _vm.duplicateRemoval,
                          callback: function ($$v) {
                            _vm.duplicateRemoval = $$v
                          },
                          expression: "duplicateRemoval",
                        },
                      },
                      [_vm._v("跳过已成功复制的商品")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showSet = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveSetting } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }