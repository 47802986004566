var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "图中图", visible: _vm.show },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "tip-box" }, [
            _c("p", [
              _vm._v(
                " 1.图片1为底图，可以选用SKU原图、主图、本地上传图片，若选多张图则随机匹配底图"
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" 2.图片2为中间图，建议选择原SKU图")]),
            _vm._v(" "),
            _c("p", [_vm._v(" 3.算力消耗：每换1个SKU的图片，消耗1算力")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        4.运行速度和电脑配置以及SKU数量有关，建议每次修改的SKU图片数量在500个以内，以免造成卡顿情况，若长时间卡顿可刷新页面重新尝试或联系客服"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-img-box",
              on: { click: _vm.closePopoverVisible },
            },
            [
              _c("div", { staticClass: "box-left" }, [
                _c(
                  "div",
                  { staticClass: "box-left-item" },
                  [
                    _c("p", { staticClass: "tl" }, [_vm._v("图片1：")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: _vm.showHandle,
                              change: function ($event) {
                                return _vm.handleRadioChange($event, 1)
                              },
                            },
                            model: {
                              value: _vm.selectedRadio1,
                              callback: function ($$v) {
                                _vm.selectedRadio1 = $$v
                              },
                              expression: "selectedRadio1",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", border: "", size: "mini" },
                              },
                              [_vm._v("采用原SKU图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandle(1)
                                  },
                                },
                              },
                              [_vm._v("选择主图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "3", border: "", size: "mini" },
                              },
                              [_vm._v("本地上传")]
                            ),
                          ],
                          1
                        ),
                        _vm._v("\n          已选择0个，"),
                        _c("span", [_vm._v("查看")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "80",
                          trigger: "click",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.popoverVisible1,
                          callback: function ($$v) {
                            _vm.popoverVisible1 = $$v
                          },
                          expression: "popoverVisible1",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGroupChange($event, 1)
                                  },
                                },
                                model: {
                                  value: _vm.selectedCheckboxes1,
                                  callback: function ($$v) {
                                    _vm.selectedCheckboxes1 = $$v
                                  },
                                  expression: "selectedCheckboxes1",
                                },
                              },
                              _vm._l(_vm.skuListBox, function (item, index) {
                                return _c("el-checkbox", {
                                  attrs: { label: item.imgNum },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      ref: "radioTrigger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-left-item" },
                  [
                    _c("p", { staticClass: "tl" }, [_vm._v("图片2：")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: {
                              change: function ($event) {
                                return _vm.handleRadioChange($event, 2)
                              },
                            },
                            model: {
                              value: _vm.selectedRadio2,
                              callback: function ($$v) {
                                _vm.selectedRadio2 = $$v
                              },
                              expression: "selectedRadio2",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", border: "", size: "mini" },
                              },
                              [_vm._v("采用原SKU图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2", border: "", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHandle(2)
                                  },
                                },
                              },
                              [_vm._v("选择主图")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "3", border: "", size: "mini" },
                              },
                              [_vm._v("本地上传")]
                            ),
                          ],
                          1
                        ),
                        _vm._v("\n          已选择0个，"),
                        _c("span", [_vm._v("查看")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "80",
                          trigger: "click",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.popoverVisible2,
                          callback: function ($$v) {
                            _vm.popoverVisible2 = $$v
                          },
                          expression: "popoverVisible2",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGroupChange($event, 2)
                                  },
                                },
                                model: {
                                  value: _vm.selectedCheckboxes2,
                                  callback: function ($$v) {
                                    _vm.selectedCheckboxes2 = $$v
                                  },
                                  expression: "selectedCheckboxes2",
                                },
                              },
                              _vm._l(_vm.skuListBox, function (item, index) {
                                return _c("el-checkbox", {
                                  attrs: { label: item.imgNum },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      ref: "radioTrigger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "box-left-item" }, [
                  _c("p", { staticClass: "tl" }, [_vm._v("图片2出现位置：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radio-box" },
                    _vm._l(5, function (item, index) {
                      return _c("div", { staticClass: "radio-item" }, [
                        _vm.radioIndex == index
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/radio2.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseCheck(index)
                                },
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/radio1.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseCheck(index)
                                },
                              },
                            }),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pre-view-right" }, [
                _vm.mainImg
                  ? _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.mainImg, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.position == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "img-box",
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          left: "0",
                        },
                      },
                      [
                        _vm.img
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.img, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.position == 2
                  ? _c(
                      "div",
                      {
                        staticClass: "img-box",
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0",
                        },
                      },
                      [
                        _vm.img
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.img, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.position == 3
                  ? _c(
                      "div",
                      {
                        staticClass: "img-box",
                        staticStyle: {
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                        },
                      },
                      [
                        _vm.img
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.img, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.position == 4
                  ? _c(
                      "div",
                      {
                        staticClass: "img-box",
                        staticStyle: {
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                        },
                      },
                      [
                        _vm.img
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.img, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.position == 5
                  ? _c(
                      "div",
                      {
                        staticClass: "img-box",
                        staticStyle: {
                          width: "48%",
                          height: "48%",
                          position: "absolute",
                          left: "calc(50% - 60px)",
                          top: "calc(50% - 60px)",
                        },
                      },
                      [
                        _vm.img
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.img, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitHandle } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("uploadImage", {
        ref: "uploadImageRef",
        attrs: { show: _vm.uploadImageVisible },
        on: {
          "update:show": function ($event) {
            _vm.uploadImageVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }