var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "_container" },
      [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c("span", { staticClass: "toptile" }, [_vm._v("爆款裂变")]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "drap-btn",
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.closeDialog },
              },
              [_vm._v("回退")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "wrapper-box" }, [
          _c("div", { staticClass: "top-bg" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "content-box" }, [
              _c(
                "div",
                { staticClass: "row", staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-right": "20px" },
                    },
                    [
                      _c("span", { staticClass: "title" }, [_vm._v("店铺")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "custom-select",
                          attrs: {
                            "popper-append-to-body": false,
                            placeholder: "请选择",
                            size: "mini",
                          },
                          on: { change: _vm.storeChange },
                          model: {
                            value: _vm.valueSelect,
                            callback: function ($$v) {
                              _vm.valueSelect = $$v
                            },
                            expression: "valueSelect",
                          },
                        },
                        _vm._l(_vm.optionsSelect, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                disabled: item.onlineStatus == 0,
                                label: item.mallName,
                                value: item.id,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.mallName)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        [_vm._v("裂变商品数")]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { size: "mini", placeholder: "请输入商品数量" },
                        on: { change: _vm.inputHandle },
                        model: {
                          value: _vm.splitNumber,
                          callback: function ($$v) {
                            _vm.splitNumber = $$v
                          },
                          expression: "splitNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-left": "40px" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("第一张主图:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.mainPicture,
                            callback: function ($$v) {
                              _vm.mainPicture = $$v
                            },
                            expression: "mainPicture",
                          },
                        },
                        [_vm._v("原主图")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.mainPicture,
                            callback: function ($$v) {
                              _vm.mainPicture = $$v
                            },
                            expression: "mainPicture",
                          },
                        },
                        [_vm._v("自定义换图")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm.mainPicture == 1,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.upLoadImgHandle($event)
                            },
                          },
                        },
                        [_vm._v("本地上传")]
                      ),
                      _vm._v(" "),
                      _vm.uploadNum != 0
                        ? [
                            _c("span", [
                              _vm._v("已选择" + _vm._s(_vm.uploadNum) + "个"),
                            ]),
                            _vm._v(","),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#0a6aa1",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.showImage($event)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("裂变标题设置:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.orPic,
                            callback: function ($$v) {
                              _vm.orPic = $$v
                            },
                            expression: "orPic",
                          },
                        },
                        [_vm._v("原标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.orPic,
                            callback: function ($$v) {
                              _vm.orPic = $$v
                            },
                            expression: "orPic",
                          },
                        },
                        [_vm._v("自定义换标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", disabled: _vm.orPic == 1 },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.upLoadTitle($event)
                                },
                              },
                            },
                            [_vm._v("本地上传")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            ref: "fileInputs",
                            staticStyle: { display: "none" },
                            attrs: { type: "file", accept: ".xlsx, .xls" },
                            on: { change: _vm.handleFileUpload },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.excelData.length > 0
                        ? [
                            _c("span", [
                              _vm._v(
                                "已选择" + _vm._s(_vm.excelData.length) + "个"
                              ),
                            ]),
                            _vm._v(","),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#0a6aa1",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.titleBoxVisible = true
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("SKU文字设置:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.skuText,
                            callback: function ($$v) {
                              _vm.skuText = $$v
                            },
                            expression: "skuText",
                          },
                        },
                        [_vm._v("原文字")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.skuText,
                            callback: function ($$v) {
                              _vm.skuText = $$v
                            },
                            expression: "skuText",
                          },
                        },
                        [_vm._v("纯净版")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "3" },
                          model: {
                            value: _vm.skuText,
                            callback: function ($$v) {
                              _vm.skuText = $$v
                            },
                            expression: "skuText",
                          },
                        },
                        [_vm._v("AI換SKU文字")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "4" },
                          model: {
                            value: _vm.skuText,
                            callback: function ($$v) {
                              _vm.skuText = $$v
                            },
                            expression: "skuText",
                          },
                        },
                        [
                          _c("span", [_vm._v("统一増加")]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "88px" },
                              attrs: {
                                size: "mini",
                                disabled: _vm.skuText != 4,
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.acModel,
                                callback: function ($$v) {
                                  _vm.acModel = $$v
                                },
                                expression: "acModel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "最前面", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "最后面", value: "2" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            attrs: { size: "mini", disabled: _vm.skuText != 4 },
                            model: {
                              value: _vm.skuFixTitle,
                              callback: function ($$v) {
                                _vm.skuFixTitle = $$v
                              },
                              expression: "skuFixTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-left": "20px" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("SKU价格设置:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.skuPrice,
                            callback: function ($$v) {
                              _vm.skuPrice = $$v
                            },
                            expression: "skuPrice",
                          },
                        },
                        [_vm._v("原价格")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.skuPrice,
                            callback: function ($$v) {
                              _vm.skuPrice = $$v
                            },
                            expression: "skuPrice",
                          },
                        },
                        [
                          _c("span", [_vm._v(" 原价增减随机值，范围")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              size: "mini",
                              disabled: _vm.skuPrice != 2,
                            },
                            model: {
                              value: _vm.skuPriceVal1,
                              callback: function ($$v) {
                                _vm.skuPriceVal1 = $$v
                              },
                              expression: "skuPriceVal1",
                            },
                          }),
                          _vm._v("\n                -\n                "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              size: "mini",
                              disabled: _vm.skuPrice != 2,
                            },
                            model: {
                              value: _vm.skuPriceVal2,
                              callback: function ($$v) {
                                _vm.skuPriceVal2 = $$v
                              },
                              expression: "skuPriceVal2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                            "font-size": "15px",
                          },
                        },
                        [_vm._v("商品发布模式:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: { input: _vm.radioInput },
                          model: {
                            value: _vm.releaseMode,
                            callback: function ($$v) {
                              _vm.releaseMode = $$v
                            },
                            expression: "releaseMode",
                          },
                        },
                        [_vm._v("立即上架")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          on: { input: _vm.radioInput },
                          model: {
                            value: _vm.releaseMode,
                            callback: function ($$v) {
                              _vm.releaseMode = $$v
                            },
                            expression: "releaseMode",
                          },
                        },
                        [_vm._v("放入已下架")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "3" },
                          on: { input: _vm.radioInput },
                          model: {
                            value: _vm.releaseMode,
                            callback: function ($$v) {
                              _vm.releaseMode = $$v
                            },
                            expression: "releaseMode",
                          },
                        },
                        [_vm._v("放入草稿箱")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            margin: "0 15px 10px",
                            display: "inline-block",
                            "font-size": "14px",
                          },
                        },
                        [_vm._v("运费模板:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini", placeholder: "请选择" },
                          on: { change: _vm.getPostTemplate },
                          model: {
                            value: _vm.templateVal,
                            callback: function ($$v) {
                              _vm.templateVal = $$v
                            },
                            expression: "templateVal",
                          },
                        },
                        _vm._l(_vm.templatePostList, function (item) {
                          return _c("el-option", {
                            key: item.templateId,
                            attrs: {
                              label: item.templateName,
                              size: "mini",
                              value: item.templateId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tip" },
                [
                  _c("span", [
                    _vm._v(
                      "\n              温馨提示：填负数则为减价格，填正数则为原价格。AI换标题、水印等功能将消耗算力\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { background: "#1849F8" },
                      attrs: { size: "mini", type: "primary", round: "" },
                      on: { click: _vm.preViewHandle },
                    },
                    [_vm._v("预览\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        background: "#9EDAFF",
                        color: "#454545",
                        border: "none",
                      },
                      attrs: { size: "mini", type: "info", round: "" },
                      on: { click: _vm.startSplit },
                    },
                    [_vm._v("开始铺货\n            ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bg-box" },
            [
              _c("div", { staticClass: "goods-info-box" }, [
                _c("div", { staticClass: "info-g" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text" },
                          on: { click: _vm.checkAll },
                        },
                        [_vm._v("全选")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text" },
                          on: { click: _vm.checkAllNo },
                        },
                        [_vm._v("反选")]
                      ),
                      _vm._v("\n              产品信息\n            "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-box" }, [
                    _c("img", {
                      staticClass: "img-g",
                      attrs: { src: _vm.detailInfo.goodsPicture, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-txt" }, [
                      _c("div", { staticClass: "txt-til" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.detailInfo.goodsName) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "g-id" }, [
                        _vm._v(
                          "商品ID:\n                  " +
                            _vm._s(_vm.detailInfo.goodsId) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("价格")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-box" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          color: "#FF003E",
                          "line-height": "20px",
                        },
                      },
                      [
                        _vm._v(
                          "拼单价：" +
                            _vm._s(_vm.detailInfo.minGroupPrice) +
                            "-" +
                            _vm._s(_vm.detailInfo.maxGroupPrice) +
                            "￥"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "单买价：" +
                          _vm._s(_vm.detailInfo.minSinglePurchasePrice) +
                          "-" +
                          _vm._s(_vm.detailInfo.maxSinglePurchasePrice) +
                          "￥"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "参考价：" + _vm._s(_vm.detailInfo.marketPrice) + "￥"
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sku-info" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("SKU")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sku-info" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.detailInfo.sku) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pre-sale" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("是否预售")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sku-info" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.detailInfo.presaleWay) +
                        "\n            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.isShowPreview
                ? _c("splitEditInfo", { ref: "splitEditInfos" })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("uploadImage", {
          ref: "uploadImageRef",
          attrs: { show: _vm.uploadImageVisible },
          on: {
            "update:show": function ($event) {
              _vm.uploadImageVisible = $event
            },
          },
        }),
        _vm._v(" "),
        _c("showDialog", {
          ref: "showDialogRef",
          attrs: { show: _vm.isShowDialog },
          on: {
            "update:show": function ($event) {
              _vm.isShowDialog = $event
            },
          },
        }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "查看",
              width: "1100px",
              visible: _vm.titleBoxVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.titleBoxVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "up-img-box" },
              [
                _c("div", { staticClass: "oldTitle" }, [
                  _c("span", [_vm._v("原商品标题:")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "【亲肤/隐形】被子固定器安全不跑针被子防跑家用被套床单棉被固定"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "incTitle" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "12px" },
                        model: {
                          value: _vm.checkedTitle,
                          callback: function ($$v) {
                            _vm.checkedTitle = $$v
                          },
                          expression: "checkedTitle",
                        },
                      },
                      [_vm._v("启动标题搜索")]
                    ),
                    _vm._v(" "),
                    _c("el-button", { attrs: { size: "mini" } }, [
                      _vm._v("导入标题txt"),
                    ]),
                    _vm._v(" "),
                    _c("el-button", { attrs: { size: "mini" } }, [
                      _vm._v("粘贴自定义标题"),
                    ]),
                    _vm._v(" "),
                    _c("el-button", { attrs: { size: "mini" } }, [
                      _vm._v("粘贴原标题"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("div", { staticClass: "titleSet" }, [
                          _vm._v("标题搜索设置"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            ref: "formSearch",
                            attrs: {
                              model: _vm.formSearch,
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "标题关键词" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入关键词" },
                                  model: {
                                    value: _vm.formSearch.titleKeyWord,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formSearch,
                                        "titleKeyWord",
                                        $$v
                                      )
                                    },
                                    expression: "formSearch.titleKeyWord",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "20px" } },
                              [
                                _c("span", [_vm._v("搜索")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: { width: "80px" },
                                  model: {
                                    value: _vm.formSearch.titleKeyWord,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formSearch,
                                        "titleKeyWord",
                                        $$v
                                      )
                                    },
                                    expression: "formSearch.titleKeyWord",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("个标题")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#BABABA",
                                      "font-size": "12px",
                                    },
                                  },
                                  [_vm._v("(最多支持200个标题)")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary", size: "small" } },
                                  [_vm._v("搜索标题")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("div", { staticClass: "titleSet" }, [
                          _vm._v("处理标题设置"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            ref: "formSet",
                            attrs: {
                              model: _vm.formSet,
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "删除内容" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.formSet.delContent,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formSet, "delContent", $$v)
                                      },
                                      expression: "formSet.delContent",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("删除英文"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("删除数字"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("删除空格"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 4 } }, [
                                      _vm._v("删除括号及内容"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "删除标题关键词",
                                  "label-width": "110px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [_vm._v("设置")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "14px" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("当标题超过60个字符时删除"),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "140px" },
                                            model: {
                                              value:
                                                _vm.formSet.delTitleKeyWord,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formSet,
                                                  "delTitleKeyWord",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formSet.delTitleKeyWord",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "1",
                                                value: "最前面字符",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "2",
                                                value: "最后面字符",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "替换标题关键词",
                                  "label-width": "110px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [_vm._v("设置")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "14px" },
                                      },
                                      [
                                        _c("span", [_vm._v("标题前缀：")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "140px" },
                                          model: {
                                            value:
                                              _vm.formSet.replaceTitleKeyWord,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formSet,
                                                "replaceTitleKeyWord",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formSet.replaceTitleKeyWord",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标题后缀" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "140px" },
                                  model: {
                                    value: _vm.formSet.titleSuffix,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formSet, "titleSuffix", $$v)
                                    },
                                    expression: "formSet.titleSuffix",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary", size: "small" } },
                                  [_vm._v("一键处理标题")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.excelData, height: "320" },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "#",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "val", label: "商品标题" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                staticStyle: { width: "440px" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入商品数量",
                                  "show-word-limit": "",
                                  maxlength: "60",
                                  clearable: "",
                                },
                                model: {
                                  value: scope.row.val,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "val", $$v)
                                  },
                                  expression: "scope.row.val",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-button", { attrs: { type: "text" } }, [
                                _vm._v("移除"),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "dialog-footer" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.titleBoxVisible = false
                            },
                          },
                        },
                        [_vm._v("导入标题列表")]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "最终导入标题个数=最终裂变复制个数,计划裂变 30 个"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("不导入重复标题")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-button", { attrs: { size: "mini" } }, [
                        _vm._v("批量移除"),
                      ]),
                      _vm._v(" "),
                      _c("el-button", { attrs: { size: "mini" } }, [
                        _vm._v("增加行标题"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("p", { staticClass: "txt" }, [
        _vm._v(
          "1.爆款裂变功能的使用会消耗次数，裂变1个商品将消耗1个次数，\n            "
        ),
        _c("strong", { staticStyle: { color: "#065af8" } }, [
          _vm._v("剩余：98258次"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "txt" }, [
        _vm._v(
          "\n            2.裂变无法保证百分之百裂变成功，但都会消耗算力，裂变失败的商品将会放入草稿箱，可以稍做作修改然后上架，少数商品可能出现裂变失败并且无法放入草稿箱，敬请谅解。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }